import type { AnchorHTMLAttributes, FC, MouseEventHandler } from 'react'
import Link from '@components/ui/Link'
import { Strings } from '@goatlab/js-utils'
import { env } from '@src/_env/env'
import { siteSettings } from '@src/api/settings/site-settings'
import cn, { clsx } from 'clsx'
import { EditText } from 'react-edit-text'
import { ImageFixedHeight } from './images/ImageFixedHeight'

type LogoProps = {
  text?: string
  href?: string
  logoUrl?: string
  keepText?: boolean
  editable?: boolean
  height?: number
  onTextChanged?: (text: string) => void
  onMouseEnter?: MouseEventHandler<HTMLAnchorElement> | undefined
  //
} & AnchorHTMLAttributes<HTMLAnchorElement>

export const Logo: FC<LogoProps> = ({
  className,
  href,
  editable,
  keepText,
  text,
  onTextChanged,
  logoUrl,
  height,
}) => {
  const textClass = clsx('font-semibold text-gray-600 pl-2', {
    hidden: !keepText,
  })

  return (
    <Link
      href={editable ? '#' : href || siteSettings.logo.href}
      className={cn('inline-flex items-center focus:outline-none', className)}
    >
      <ImageFixedHeight
        src={logoUrl || siteSettings.logo.url}
        alt={siteSettings.logo.alt}
        height={height || 40}
      />

      {editable ? (
        keepText && (
          <EditText
            className={textClass}
            defaultValue={text || 'Gealium'}
            onSave={(e) => {
              onTextChanged?.(e.value)
            }}
          />
        )
      ) : (
        <div className={textClass}>
          {Strings.capitalize(text || env.APP_NAME)}
        </div>
      )}
    </Link>
  )
}
