export interface RouteParam {
  accountId?: string
  productId?: string
  pageId?: string
  menuId?: string
  space?: string
}

export const STATIC_ROUTES = {
  HOME: '/',
  PRODUCT: `/products`,
  CHECKOUT: '/checkout',
  CONTACT: '/contact-us',
  TERMS: '/terms',
  POLICY: '/privacy',
  FAQ: '/faq',
  FORGET_PASSWORD: '/forget-password',
  ORGS: '/orgs',
  ORGS_STAFF: '/orgs/staff',
  ORGS_PRODUCTS: '/orgs/products',
  ORGS_SERVICES: '/orgs/services',
  LOGIN: '/signin',
  SIGN_UP: '/signup',
  SEARCH: '/search',
  COLLECTIONS: '/collections',
  CATEGORY: '/category',
  ORDER: '/order',
}

export const DYNAMIC_ROUTES = {
  ACCOUNT_PRODUCTS_APPOINTMENTS: ({ accountId }: RouteParam) =>
    `/account/${accountId}/products/appointments`,
  ACCOUNT_PRODUCTS_EXAMS: ({ accountId }: RouteParam) =>
    `/account/${accountId}/products/exams`,
  ACCOUNT_PRODUCTS_PHYSICAL: ({ accountId }: RouteParam) =>
    `/account/${accountId}/products/physical`,
  ACCOUNT_PAGES: ({ accountId }: RouteParam) => `/account/${accountId}/pages`,
  ACCOUNT_PAGES_MENUS: ({ accountId }: RouteParam) =>
    `/account/${accountId}/pages/menus`,
  ACCOUNT_PAGES_MENUS_VIEW: ({ accountId, menuId }: RouteParam) =>
    `/account/${accountId}/pages/menus/${menuId}`,
  ACCOUNT_PAGES_VIEW: ({ accountId, pageId }: RouteParam) =>
    `/account/${accountId}/pages/${pageId}`,
  ACCOUNT_PRODUCTS_VIEW: ({ accountId, productId }: RouteParam) =>
    `/account/${accountId}/products/${productId}`,
  ACCOUNT_SETTINGS: ({ accountId }: RouteParam) =>
    `/account/${accountId}/settings`,
  ACCOUNT_SETTINGS_AVAILABILITY: ({ accountId }: RouteParam) =>
    `/account/${accountId}/settings/availability`,
  ACCOUNT_SETTINGS_PAYMENTS: ({ accountId }: RouteParam) =>
    `/account/${accountId}/settings/payments`,
  ACCOUNT_SETTINGS_PAYOUTS: ({ accountId }: RouteParam) =>
    `/account/${accountId}/settings/payouts`,
  ACCOUNT_SETTINGS_TEAMS: ({ accountId }: RouteParam) =>
    `/account/${accountId}/settings/teams`,
  ACCOUNT_SETTINGS_DOMAINS: ({ accountId }: RouteParam) =>
    `/account/${accountId}/settings/domains`,
  ACCOUNT_SETTINGS_PRESCRIPTIONS: ({ accountId }: RouteParam) =>
    `/account/${accountId}/settings/prescriptions`,
  ACCOUNT_SETTINGS_MARKETPLACE: ({ accountId }: RouteParam) =>
    `/account/${accountId}/settings/marketplace`,
  ACCOUNT_SETTINGS_SOCIAL: ({ accountId }: RouteParam) =>
    `/account/${accountId}/settings/social`,
  ACCOUNT_SETTINGS_ADVERTISING: ({ accountId }: RouteParam) =>
    `/account/${accountId}/settings/advertising`,
  ACCOUNT_DASHBOARD: ({ accountId }: RouteParam) =>
    `/account/${accountId}/dashboard`,
  ACCOUNT_INBOX: ({ accountId }: RouteParam) => `/account/${accountId}/inbox`,
  ACCOUNT_APPOINTMENTS: ({ accountId }: RouteParam) =>
    `/account/${accountId}/appointments`,
  ACCOUNT_CALENDAR: ({ accountId }: RouteParam) =>
    `/account/${accountId}/calendar`,
  ACCOUNT_ORDERS: ({ accountId }: RouteParam) => `/account/${accountId}/orders`,
  ACCOUNT_PRODUCTS: ({ accountId }: RouteParam) =>
    `/account/${accountId}/products`,
  ACCOUNT_DETAILS: ({ accountId }: RouteParam) =>
    `/account/${accountId}/account-details`,
  ACCOUNT_HOME: ({ accountId }: RouteParam) => `/account/${accountId}/home`,
  ACCOUNT_NETWORK: ({ accountId }: RouteParam) =>
    `/account/${accountId}/network`,
  SPACE_HOME: ({ space }: RouteParam) => `/${space}`,
  SPACE_BIO: ({ space }: RouteParam) => `/${space}/bio`,
  SPACE_POSTS: ({ space }: RouteParam) => `/${space}/posts`,
  SPACE_PRODUCTS: ({ space }: RouteParam) => `/${space}/products`,
  SPACE_PRODUCTS_VIEW: ({ space, productId }: RouteParam) =>
    `/${space}/products/${productId}`,
  SPACE_BLOG: ({ space }: RouteParam) => `/${space}/blog`,
  SPACE_COURSES: ({ space }: RouteParam) => `/${space}/courses`,
  SPACE_STORE: ({ space }: RouteParam) => `/${space}/store`,
}
