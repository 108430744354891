import type { FC } from 'react'
import { useState } from 'react'
import { useRouter } from 'next/navigation'
import { backendHook } from '@api/trpc'
import { CreatePostDialog } from '@components/account/home/CreatePostDialog'
import { DataCenterDialog } from '@components/account/pages/DataCenterDialog'
import { NewsHubsDialog } from '@components/account/pages/NewsHubsDialog'
import { useAuth } from '@components/auth/store/auth.store'
import { Badge } from '@goatlab/react-ui'
import { useStateAwareRoutes } from '@src/hooks/useStateAwareRoutes'
import { sodiumConfig } from '@src/sodiumConfig'
import {
  CirclePlus,
  FileBarChartIcon,
  HomeIcon,
  NewspaperIcon,
  UsersRoundIcon,
} from 'lucide-react'

export const MobileFooter: FC = () => {
  const [isShowNewHubs, setIsShowNewHubs] = useState(false)
  const [isShowDataCenter, setIsShowDataCenter] = useState(false)
  const [isShowPostModal, setIsShowPostModal] = useState(false)

  const router = useRouter()
  const routes = useStateAwareRoutes()
  const { user } = useAuth()

  const { data } =
    backendHook.backend.friendship.getFriendshipNotificationsCount.useQuery(
      undefined,
      {
        enabled: !!user?.account?.id,
      },
    )
  const notificationsCount = data?.friendshipNotificationsCount || 0

  return (
    <div className="fixed bottom-0 left-0 z-20 h-12 w-full border-t border-gray-200 bg-white dark:border-gray-600 dark:bg-gray-700 md:hidden">
      <div className="mx-auto grid h-full max-w-lg grid-cols-5">
        <div className="flex items-center justify-center">
          <button
            type="button"
            className="group inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800"
            onClick={async () => {
              router.push(routes.dynamic.ACCOUNT_HOME())
            }}
          >
            <HomeIcon className="h-5 w-5" color={sodiumConfig.colors.primary} />
            <span className="text-xs text-slate-500 group-hover:text-primary dark:text-gray-400 dark:group-hover:text-primary">
              Home
            </span>
          </button>
        </div>

        <div className="relative flex items-center justify-center">
          <button
            type="button"
            onClick={() => router.push(routes.dynamic.ACCOUNT_NETWORK())}
            className="group inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800"
          >
            <UsersRoundIcon
              className="h-5 group-hover:text-primary"
              color={sodiumConfig.colors.primary}
            />
            <span className="text-xs text-slate-500 group-hover:text-primary dark:text-gray-400 dark:group-hover:text-primary">
              Network
            </span>
          </button>
          {/* Badge para las notificaciones de Network */}
          {notificationsCount > 0 && (
            <Badge variant="destructive" className="absolute right-2 top-0">
              {notificationsCount}
            </Badge>
          )}
        </div>

        <div className="group inline-flex flex-col items-center justify-center hover:bg-gray-50 dark:hover:bg-gray-800">
          <CirclePlus
            onClick={() => setIsShowPostModal(true)}
            size={36}
            className="text-slate-500 group-hover:text-primary"
            color={sodiumConfig.colors.primary}
          />
        </div>

        <button
          type="button"
          onClick={() => setIsShowNewHubs(true)}
          className="group inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800"
        >
          <NewspaperIcon
            className="h-5 group-hover:text-primary"
            color={sodiumConfig.colors.primary}
          />
          <span className="text-xs text-slate-500 group-hover:text-primary dark:text-gray-400 dark:group-hover:text-primary">
            NewsHub
          </span>
        </button>

        <button
          type="button"
          onClick={() => setIsShowDataCenter(true)}
          className="group inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800"
        >
          <FileBarChartIcon
            className="h-5 group-hover:text-primary"
            color={sodiumConfig.colors.primary}
          />
          <span className="truncate text-xs text-slate-500 group-hover:text-primary dark:text-gray-400 dark:group-hover:text-primary">
            Data Center
          </span>
        </button>
      </div>
      <NewsHubsDialog
        isOpen={isShowNewHubs}
        onClose={() => setIsShowNewHubs(false)}
      />
      <DataCenterDialog
        isOpen={isShowDataCenter}
        onClose={() => setIsShowDataCenter(false)}
      />
      <CreatePostDialog
        isDialogOpen={isShowPostModal}
        setIsDialogOpen={setIsShowPostModal}
      />
    </div>
  )
}
