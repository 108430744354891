import type { FC, ReactElement } from 'react'
import { useRouter } from 'next/router'
import { Avatar } from '@components/ui/Avatar'
import { sodiumConfig } from '@src/sodiumConfig'
import { defaultAccountImage } from '@src/utils/constants'
import { useGoToConversation } from '@src/utils/router/useGoToConversation'
import { MessageCircleMore } from 'lucide-react'

interface AccountSearchResultProps {
  account?: {
    id?: string | null
    displayName?: string | null
    profilePicture?: string | null
    title?: string | null
    slug?: string | null
    disablePrivateMessages?: boolean | null
  }
  accessoryRight?: ReactElement
  disabled?: boolean
  onAvatarPressed?: () => void
  onPressedIn?: () => void
  onPress?: () => Promise<void>
  bottomText?: ReactElement
  showDirectMessage?: boolean
}

export const AccountSearchResult: FC<AccountSearchResultProps> = ({
  account,
  accessoryRight,
  onAvatarPressed,
  onPress,
  onPressedIn,
  bottomText,
  disabled = false,
  showDirectMessage = false,
}) => {
  const router = useRouter()
  const { navigateToConversation } = useGoToConversation()

  if (!account) {
    return null
  }

  const onMessageButtonPressed = () => {
    if (!account.id || disabled) {
      return
    }

    navigateToConversation({
      conversationId: account?.id,
      account: {
        id: account.id,
        name: account.displayName,
        avatar: account.profilePicture,
      },
    })
  }

  const handlePress = async () => {
    onPressedIn?.()

    if (onPress) {
      await onPress()
      return
    }

    await router.push(`/${account.slug}`)
    onAvatarPressed?.()
  }

  return (
    <div className="flex w-full items-center px-2">
      <div
        className="flex cursor-pointer items-center justify-center"
        onClick={handlePress}
      >
        <div className="flex-shrink-0 pb-2">
          <Avatar
            uri={account?.profilePicture || defaultAccountImage}
            size={45}
          />
        </div>

        <div className="ml-4 flex flex-col justify-center">
          <div className="text-ellipsis text-[15px] font-semibold text-black">
            {account.displayName}
          </div>
          <div
            className="-mt-1 line-clamp-2 overflow-hidden text-ellipsis text-xs leading-tight text-gray-500 lg:max-w-96"
            style={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {account.title}
          </div>
          {bottomText && (
            <div className="-mt-0.5 text-[11px] text-gray-500">
              {bottomText}
            </div>
          )}
        </div>
      </div>

      <div className="ml-auto flex items-center">
        {accessoryRight ||
          (!account.disablePrivateMessages && showDirectMessage && (
            <div color="transparent" onClick={onMessageButtonPressed}>
              <MessageCircleMore
                className="h-6 w-6"
                color={sodiumConfig.colors.iconColor}
              />
            </div>
          ))}
      </div>
    </div>
  )
}
