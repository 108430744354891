import type { FC, ReactNode } from 'react'
import { Badge } from '@goatlab/react-ui'
import { formatNumberToCounter } from '@sodium/shared-frontend-schemas'
import { useIs } from '@src/hooks/useIs'
import clsx from 'clsx'
import { Button } from './Button2'

interface ButtonCountBadgeProps {
  count: number
  label?: string
  icon: ReactNode
  onClick: () => void
}

export const ButtonCountBadge: FC<ButtonCountBadgeProps> = ({
  count,
  icon,
  onClick,
  label,
}) => {
  let heightAndWith = 'h-4 w-4'
  const countText = formatNumberToCounter(count)
  const { isMobile } = useIs()

  switch (countText.length) {
    case 1:
      heightAndWith = 'h-4 w-4'
      break
    case 2:
      heightAndWith = 'h-4 w-6'
      break
    case 3:
      heightAndWith = 'h-4 w-8'
      break
    case 4:
      heightAndWith = 'h-4 w-10'
      break
    case 5:
      heightAndWith = 'h-4 w-11'
      break
    case 6:
      heightAndWith = 'h-4 w-14'
      break
    default:
      heightAndWith = 'h-4 w-4'
      break
  }

  const Wrapper = isMobile ? 'div' : Button

  return (
    <Wrapper
      onClick={onClick}
      variant="ghost"
      color="transparent"
      className="cursor-pointer"
    >
      <div className="align-center relative flex-col">
        <div className="flex flex-col items-center p-2">
          {icon}
          {!isMobile && label && <span className="text-xs">{label}</span>}
        </div>
        {count > 0 && (
          <Badge
            variant="destructive"
            className={clsx(
              'absolute right-0 top-0 flex h-4 w-4 items-center justify-center p-0 text-xs',
              heightAndWith,
            )}
          >
            {countText}
          </Badge>
        )}
      </div>
    </Wrapper>
  )
}
