import type { CarouselApi } from '@goatlab/react-ui'
import type { RouterOutputs } from '@sodium/shared-frontend-services'
import type { MarketplaceAsset } from '@sodium/shared-schemas'
import type { FC } from 'react'
import React, { memo } from 'react'
import { Carousel, CarouselContent, CarouselItem, cn } from '@goatlab/react-ui'
import { AssetRenderer } from './AssetRenderer'
import { useAssetViewer } from './useAssetViewer'

export type HomePostType =
  RouterOutputs['backend']['posts']['homeFeed']['data'][0]

interface AssetViewerProps {
  assets: HomePostType['assets']
  isViewable?: boolean
  postId?: string
  isPreview?: boolean
  className?: string // Añadimos prop para clases personalizadas
}

export const AssetViewer: FC<AssetViewerProps> = memo(
  ({ assets, isViewable, postId, isPreview, className }) => {
    const [api, setApi] = React.useState<CarouselApi>()
    const { currentAssetIndex, snapToItem, isAssetViewable } = useAssetViewer({
      isViewable,
      postId,
    })

    React.useEffect(() => {
      if (!api) {
        return
      }

      snapToItem(api.selectedScrollSnap())

      api.on('select', () => {
        snapToItem(api.selectedScrollSnap())
      })
    }, [api])

    return (
      <div
        className={cn(
          'w-full items-center justify-center bg-blue-500 align-middle', // Quitamos min-h-64 para mayor flexibilidad
          className,
        )}
      >
        <Carousel
          setApi={setApi}
          opts={{
            align: 'center',
            dragFree: false,
            active: assets.length > 1,
          }}
        >
          <CarouselContent>
            {assets.map((asset, index) => (
              <CarouselItem
                key={index}
                className="flex w-full items-center justify-center align-middle"
              >
                <AssetRenderer
                  asset={asset as MarketplaceAsset}
                  index={index}
                  currentAssetIndex={currentAssetIndex}
                  isAssetViewable={isAssetViewable || false}
                  isPreview={isPreview}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>

        {(api?.scrollSnapList().length || 0) > 1 && (
          <div className="flex items-center justify-center gap-1.5 py-2">
            {api?.scrollSnapList().map((_, index) => {
              const isCurrentIndex = index === currentAssetIndex
              return (
                <div
                  key={index}
                  className={cn(
                    'h-2.5 w-2.5 cursor-pointer rounded-full border border-blue-500 transition-colors',
                    isCurrentIndex
                      ? 'bg-blue-500'
                      : 'bg-white hover:bg-blue-300',
                  )}
                  onClick={() => {
                    api.scrollTo(index)
                  }}
                />
              )
            })}
          </div>
        )}
      </div>
    )
  },
)
