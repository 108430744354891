import type { VideoUploadProps } from '@components/ui/video/VideoUpload'
import { Ids } from '@goatlab/js-utils'
import { env } from '@src/_env/env'
import { getFileExtension, removeFileExtension } from '../files/utils'

interface ConverterEngineParams {
  videoUploadBlob: VideoUploadProps
  width?: number | null
  height?: number | null
}

interface ConverterEngine {
  convertInBackground(
    params: ConverterEngineParams,
    progress?: (props: {
      progress: number
      currentFramePreview?: string
    }) => void,
  ): Promise<{ outputVideoUri: string; originalFileName: string }>
}

export class VideoService implements ConverterEngine {
  async convertInBackground(
    params: ConverterEngineParams,
    progress?: (props: {
      progress: number
      currentFramePreview?: string
    }) => void,
  ): Promise<{
    outputVideoUri: string
    originalFileName: string
    outputVideoBlob: Blob
  }> {
    const videoUploadBlob = params.videoUploadBlob

    const fileNameWithExtension = params.videoUploadBlob.fileName
    const originalFileName = removeFileExtension(fileNameWithExtension)

    const videoWidth = params.width
    const videoHeight = params.height

    const isVertical = (videoHeight || 0) > (videoWidth || 0)

    const startTime = this.formatTime(
      params.videoUploadBlob.videoSettings.customStartTime,
    )
    const endTime = this.formatTime(
      params.videoUploadBlob.videoSettings.customEndTime,
    )

    const url = `${env.BACKEND_URL}/video/convert`

    const form = new FormData()
    form.append('video', videoUploadBlob.videoBlob)
    form.append('startTime', startTime)
    form.append('endTime', endTime)
    form.append('isVertical', isVertical.toString())
    form.append('originalFileName', originalFileName)
    form.append('originalExtension', getFileExtension(fileNameWithExtension))

    const response = await fetch(url, {
      method: 'POST',
      body: form,
    })

    const outputVideoBlob = await response.blob()

    const outputVideoUri = URL.createObjectURL(outputVideoBlob)

    progress?.({ progress: 1 })

    return {
      outputVideoUri,
      originalFileName,
      outputVideoBlob,
    }
  }

  private formatTime = (seconds: number): string => {
    if (isNaN(seconds) || seconds < 0) {
      return '00:00:00'
    }

    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = Math.floor(seconds % 60)

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`
  }

  async getThumbnailFile({
    videoBlob,
    timeSeconds = 0,
  }: {
    videoBlob: Blob
    timeSeconds?: number
  }): Promise<{
    file: File
    dimensions: { width: number; height: number }
    thumbnailBlob: Blob
  } | null> {
    try {
      const video = document.createElement('video')
      const videoUrl = URL.createObjectURL(videoBlob)
      video.src = videoUrl

      return await new Promise((resolve, reject) => {
        video.onloadedmetadata = () => {
          video.currentTime = timeSeconds

          video.onseeked = () => {
            try {
              const canvas = document.createElement('canvas')
              canvas.width = video.videoWidth
              canvas.height = video.videoHeight

              const ctx = canvas.getContext('2d')
              if (!ctx) {
                throw new Error('Failed to get canvas context')
              }

              ctx.drawImage(video, 0, 0, canvas.width, canvas.height)

              canvas.toBlob(
                (blob) => {
                  if (!blob) {
                    reject(new Error('Failed to create thumbnail blob'))
                    return
                  }

                  const outputFileName = `thumbnail_${Ids.uuid()}.jpg`
                  const thumbnailFile = new File([blob], outputFileName, {
                    type: 'image/jpeg',
                  })

                  URL.revokeObjectURL(videoUrl)

                  resolve({
                    file: thumbnailFile,
                    dimensions: { width: canvas.width, height: canvas.height },
                    thumbnailBlob: blob,
                  })
                },
                'image/jpeg',
                0.95,
              )
            } catch (err) {
              reject(new Error(`Video processing failed: ${err}`))
            }
          }

          video.onerror = () => {
            URL.revokeObjectURL(videoUrl)
            // Check if the event provides a meaningful message via video.error
            const errorMessage =
              video.error?.message || 'Unknown video processing error'
            reject(new Error(`Video processing error: ${errorMessage}`))
          }
        }

        video.onerror = () => {
          URL.revokeObjectURL(videoUrl)
          // Check if the event provides a meaningful message via video.error
          const errorMessage =
            video.error?.message || 'Unknown video loading error'
          reject(new Error(`Video loading error: ${errorMessage}`))
        }
      })
    } catch (err) {
      console.error('Error al generar la miniatura:', err)
      return null
    }
  }
}

export const videoService = new VideoService()
