import type { FC } from 'react'
import { Strings } from '@goatlab/js-utils'

interface LabelProps {
  text?: string | null
  required?: boolean
}

export const Label: FC<LabelProps> = ({ text, required }) => {
  const label = Strings.capitalize(text ?? '')
  return (
    <label
      htmlFor={label ?? undefined}
      className="mb-3 block text-sm leading-none text-black"
    >
      {label}

      {required && <span className="text-rose-500"> *</span>}
    </label>
  )
}
