import React, { useRef } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useAuth } from '@components/auth/store/auth.store'
import { SearchBox } from '@components/search/globalSearch/SearchBox'
import { Button } from '@components/ui/buttons/Button2'
import { ButtonCountBadge } from '@components/ui/buttons/ButtonCountBadge'
import { LanguageSwitcher } from '@components/ui/LanguageSwitcher'
import { Logo } from '@components/ui/Logo'
import { GealiumCategoryMenu } from '@components/ui/menus/GealiumCategoryMenu'
import { SpaceMenu } from '@components/ui/menus/SpaceMenu'
import { NoSSR } from '@components/ui/utils/NoSSR'
import { useSite } from '@contexts/site/site.context'
import { useLocalization } from '@goatlab/marketplace-i18n'
import { SidebarTrigger, useScrollHeight } from '@goatlab/react-ui'
import { useUIStore } from '@sodium/shared-frontend-schemas'
import { env } from '@src/_env/env'
import { backendHook } from '@src/api/trpc'
import { useIs } from '@src/hooks/useIs'
import { useStateAwareRoutes } from '@src/hooks/useStateAwareRoutes'
import {
  formatMenuFromCategories,
  formatMenuFromWPMenus,
} from '@src/utils/buildHeaders'
import { useGoToConversation } from '@src/utils/router/useGoToConversation'
import { useTypedTranslation } from '@src/utils/useTypedTranslation'
import { useAddActiveScroll } from '@utils/add-active-scroll'
import clsx from 'clsx'
import { HomeIcon, UsersRoundIcon } from 'lucide-react'
import { AiOutlineMessage } from 'react-icons/ai'
import { sodiumConfig } from '../../../sodiumConfig'

const AuthMenu = dynamic(
  () => import('./authMenu').then((mod) => mod.default),
  { ssr: false },
)
const CartButton = dynamic(
  () => import('@components/cart/cartButton').then((mod) => mod.default),
  {
    ssr: false,
  },
)

const AIComponent = sodiumConfig.ai.chatbotLogo ?? 'div'

type DivElementRef = React.MutableRefObject<HTMLDivElement>

interface MainTopHeaderProps {
  logo?: string
}

export const MainTopHeader: React.FC<MainTopHeaderProps> = () => {
  const { t } = useTypedTranslation('common')
  const currentLanguage = useLocalization().currentLanguageBackendFormat()
  const { navbarOffset } = useScrollHeight()
  const { isSpace, menuItems, logo, isExternalDomain } = useSite()
  const router = useRouter()
  const routes = useStateAwareRoutes()
  const { user } = useAuth()

  const { data: gealiumCategories } =
    backendHook.commerce.categories.grouped.useQuery(
      {
        lang: currentLanguage,
      },
      {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      },
    )

  const { data } =
    backendHook.backend.friendship.getFriendshipNotificationsCount.useQuery(
      undefined,
      {
        enabled: !!user?.account?.id,
      },
    )
  const notificationsCount = data?.friendshipNotificationsCount || 0

  const { data: messageCount } =
    backendHook.backend.chats.getUnreadConversationsCount.useQuery(undefined, {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      enabled: !!user?.account,
    })
  const inboxCount = messageCount || 0

  const shouldDisplaySpaceMenuOnly = !!(isSpace && isExternalDomain)
  const gealiumCategoryMenu = formatMenuFromCategories(gealiumCategories)
  const spaceMenu = formatMenuFromWPMenus(menuItems)

  const { openSearch } = useUIStore()
  const { isMobile, isTablet, isAccountPage } = useIs()
  const { isAuthenticated } = useAuth()
  const isUserAuthenticated = isAuthenticated()

  const siteHeaderRef = useRef() as DivElementRef

  const { navigateToConversation } = useGoToConversation()

  const chatboAccountId = env.local
    ? 'bhtrIpRQlmpUwptSUvNcw'
    : env.dev
      ? 'se1k1Aco63SO18KjcUBOm'
      : 'lvtL9WUPJnhSNqmGpG-2K'

  const onAIPressed = () => {
    navigateToConversation({
      conversationId: chatboAccountId,
    })
  }
  useAddActiveScroll(siteHeaderRef)

  return (
    <header
      id="siteHeader"
      ref={siteHeaderRef}
      // className={clsx('active sticky z-50 h-14 w-full')}
      className="fixed left-0 right-0 top-0 z-50 h-14 w-full"
    >
      <div
        className="body-font fixed z-20 h-14 w-full bg-white ps-6 text-gray-700 transition duration-200 ease-in-out"
        style={{
          top: `${navbarOffset}px`,
        }}
      >
        <div className="flex h-full w-full items-center pr-4 before:absolute before:bottom-0 before:h-px before:w-screen before:bg-[#F1F1F1]">
          <div className="inline-flex w-3/12 items-center justify-start">
            {isUserAuthenticated && isAccountPage && (
              <div className="-ml-3 pr-2">
                <SidebarTrigger />
              </div>
            )}
            {isMobile ? (
              isUserAuthenticated ? (
                <AuthMenu />
              ) : (
                <div className="-ml-3 pr-2 pt-1">
                  <Logo
                    keepText={false}
                    logoUrl={shouldDisplaySpaceMenuOnly ? logo : ''}
                  />
                </div>
              )
            ) : (
              <Logo
                keepText={!shouldDisplaySpaceMenuOnly}
                logoUrl={shouldDisplaySpaceMenuOnly ? logo : ''}
              />
            )}
          </div>

          {sodiumConfig.isMarketPlace && !isMobile && !isTablet && (
            <div className="mr-6 flex w-5/12 justify-around sm:block 2xl:me-12 3xl:me-20">
              <div className="headerTopMenu ms-7 pe-2 transition-all duration-100 ease-in-out lg:flex xl:ms-9">
                {shouldDisplaySpaceMenuOnly ? (
                  <SpaceMenu menu={spaceMenu} />
                ) : (
                  <GealiumCategoryMenu categoryMenu={gealiumCategoryMenu} />
                )}
              </div>
            </div>
          )}

          <div className="flex w-10/12 justify-between transition-all">
            {/* Left-aligned SearchBox */}
            <div className="align-center m-2 flex">
              <div className="flex flex-col justify-center">
                <SearchBox onFocus={openSearch} />
              </div>
            </div>

            {/* Right-aligned group of buttons and icons */}
            <div className="flex items-center justify-end">
              {!isMobile && isUserAuthenticated && (
                <Button
                  onClick={async () => {
                    await router.push(routes.dynamic.ACCOUNT_HOME())
                  }}
                  variant="ghost"
                  color="transparent"
                >
                  <div className="align-center flex-col">
                    <div className="flex flex-col items-center">
                      <HomeIcon className="mb-1 h-5 w-5" />
                      <span className="text-xs">Home</span>
                    </div>
                  </div>
                </Button>
              )}

              {!isMobile && isUserAuthenticated && (
                <ButtonCountBadge
                  onClick={async () => {
                    await router.push(routes.dynamic.ACCOUNT_NETWORK())
                  }}
                  icon={<UsersRoundIcon className="mb-1 h-5 w-5" />}
                  label={'Network'}
                  count={notificationsCount}
                />
              )}

              {isUserAuthenticated && (
                <ButtonCountBadge
                  onClick={async () => {
                    await router.push(routes.dynamic.ACCOUNT_INBOX())
                  }}
                  icon={
                    <AiOutlineMessage
                      className={clsx('mb-1', {
                        'h-5 w-5': !isMobile,
                        'h-7 w-7': isMobile,
                      })}
                    />
                  }
                  label={t('text-inbox')}
                  count={inboxCount}
                />
              )}
              {isUserAuthenticated && (
                <ButtonCountBadge
                  onClick={onAIPressed}
                  icon={
                    <AIComponent
                      className={clsx('mb-1', {
                        'h-5 w-5': !isMobile,
                        'h-7 w-7': isMobile,
                      })}
                    />
                  }
                  label={'AI'}
                  count={0}
                />
              )}

              <NoSSR>
                <div className="hidden md:flex">
                  {!isMobile && !isUserAuthenticated && (
                    <LanguageSwitcher name="language" />
                  )}
                </div>
              </NoSSR>

              {sodiumConfig.isMarketPlace && (
                <div className="flex flex-col items-center justify-center">
                  <CartButton />
                </div>
              )}

              {((isMobile && !user?.account) || !isMobile) && (
                <div className="ml-4">
                  <AuthMenu />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
