import type { Unpacked } from '@goatlab/js-utils'
import type {
  TypedPathWrapper,
  UseZodFormHookReturnType,
} from '@goatlab/react-zod-form'
import type { ComponentType, FC } from 'react'
import type { OptionProps } from 'react-select'
import { useEffect } from 'react'
import { siteSettings } from '@api/settings/site-settings'
import { useLocalization } from '@goatlab/marketplace-i18n'
import { useParseInputProps } from '@goatlab/react-zod-form'
import { Select } from './forms/fields/Select'

type SelectedLanguage = Unpacked<typeof siteSettings.siteHeader.languageMenu>

const CustomOption: ComponentType<OptionProps<SelectedLanguage, false>> = ({
  data,
  ...props
}) => (
  <div {...props.innerProps} className="flex w-40 items-center overflow-hidden">
    <div className="ml-4 h-4 w-5 flex-shrink-0">{data.icon}</div>
    <span className="m-2 w-32 flex-grow overflow-hidden">{data.label}</span>
  </div>
)

export interface LanguageSwitcherProps {
  name: string | TypedPathWrapper<any, any>
  label?: string | null
  defaultValue?: string | null
  onChange?: (value: string) => void
  formHook?: UseZodFormHookReturnType<any>
  tabIndex?: number
  inModal?: boolean
}

export const LanguageSwitcher: FC<LanguageSwitcherProps> = ({
  name: nameProp,
  defaultValue,
  onChange,
  formHook: hook,
  label,
  tabIndex,
  inModal,
}) => {
  const { siteHeader } = siteSettings
  const options: SelectedLanguage[] = siteHeader.languageMenu
  const localization = useLocalization()

  const { name, formHook } = useParseInputProps({
    name: nameProp,
    formHook: hook,
  })

  const handleItemClick = async (value: string) => {
    onChange?.(value)
    localization.changeLanguage(value)
  }

  useEffect(() => {}, [defaultValue])

  return (
    <Select
      className="w-full"
      name={name}
      label={label}
      options={options}
      defaultValue={defaultValue}
      isClearable={false}
      tabIndex={tabIndex}
      onChange={handleItemClick}
      formHook={formHook}
      // @ts-ignore
      components={{ Option: CustomOption }}
      searchable={true}
      inModal={inModal}
    />
  )
}
