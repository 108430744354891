import type { MarketplaceAsset } from '@sodium/shared-schemas'
import type { MarketplaceUploadHookResponse } from '@src/types/assets'
import React, { useEffect, useState } from 'react'
import { backendHook } from '@api/trpc'
import { Button } from '@components/ui/buttons/Button2'
import { DocumentUpload } from '@components/ui/documents/DocumentUpload'
import { RichTextArea } from '@components/ui/forms/richText/RichTextArea'
import { ImageUpload } from '@components/ui/images/ImageUpload'
import { VideoUpload } from '@components/ui/video/VideoUpload'
import { useTypedTranslation } from '@goatlab/marketplace-i18n'
import {
  Credenza,
  CredenzaBody,
  CredenzaContent,
  CredenzaFooter,
  CredenzaHeader,
  CredenzaTitle,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  toastSonner,
} from '@goatlab/react-ui'
import { Form, useZodFormHook } from '@goatlab/react-zod-form'
import {
  useAccountStore,
  useNewPostStore,
} from '@sodium/shared-frontend-schemas'
import { useDocumentUpload } from '@src/hooks/uploads/useDocumentUpload'
import { useImageUpload } from '@src/hooks/uploads/useImageUploads'
import { useVideoUpload } from '@src/hooks/uploads/useVideoUploads'
import { useIs } from '@src/hooks/useIs'
import { z } from 'zod'
import { AssetViewer } from '../post/assetViewer/AssetViewer'
import { QuickShareToggle, useQuickShareStore } from './DialogQuickShare'

type UploadType = 'image' | 'video' | 'document'

const newPostSchema = z.object({
  content: z.string(),
})

export const replaceAssetOnceUploaded = (
  hook: MarketplaceUploadHookResponse,
  assets: MarketplaceAsset[],
  replaceAsset: (
    oldAsset: MarketplaceAsset,
    newAsset: MarketplaceAsset,
  ) => void,
  reset: () => void,
) => {
  const { finalAssetUrl, success, done, assetMetadata, localAssetUrl } = hook

  if (finalAssetUrl && success && done && assetMetadata) {
    const isAssetPresent = assets.find((asset) => asset.url === localAssetUrl)
    if (isAssetPresent) {
      replaceAsset(isAssetPresent, {
        ...assetMetadata,
        url: finalAssetUrl,
      })
    }
    reset()
  }
}

export const addLocalAssetIfNotPresent = (
  hook: MarketplaceUploadHookResponse,
  assets: MarketplaceAsset[],
  addAsset: (newAsset: MarketplaceAsset) => void,
) => {
  const { localAssetUrl, assetMetadata } = hook

  const isAssetPresent = assets.find((asset) => asset.url === localAssetUrl)

  if (!isAssetPresent && assetMetadata && localAssetUrl) {
    addAsset({
      ...assetMetadata,
      url: localAssetUrl,
    })
  }
}

export const useHandleFileUpload = (
  uploadHook: MarketplaceUploadHookResponse,
  assets: MarketplaceAsset[],
  replaceAsset: (
    oldAsset: MarketplaceAsset,
    newAsset: MarketplaceAsset,
  ) => void,
  addAsset: (newAsset: MarketplaceAsset) => void,
) => {
  useEffect(() => {
    replaceAssetOnceUploaded(uploadHook, assets, replaceAsset, uploadHook.reset)
  }, [uploadHook.done])

  useEffect(() => {
    addLocalAssetIfNotPresent(uploadHook, assets, addAsset)
  }, [uploadHook.localAssetUrl])
}

export const CreatePostDialog = ({
  isDialogOpen,
  setIsDialogOpen,
}: {
  isDialogOpen: boolean
  setIsDialogOpen: (isDialogOpen: boolean) => void
}) => {
  const [uploadType, setUploadType] = useState<UploadType | null>(null)
  const { selectedAccount } = useAccountStore()
  const { isMobile } = useIs()

  const {
    // TODO: Evaluar que se va a ocupar en este nivel del hook y que no
    hasAssets,
    postHasContent,
    isLoadingMedia,
    isPosting,
    setIsPosting,
    assets,
    setContent,
    content,
    reset,
    postId,
    replaceAsset,
    addAsset,
    setIsLoadingMedia,
    isAdvertisingPost,
  } = useNewPostStore()
  const { selectedSocialAccounts } = useQuickShareStore()

  const createPost = backendHook.backend.posts.createPost.useMutation()
  const updatePost = backendHook.backend.posts.updatePost.useMutation()
  const utils = backendHook.useUtils()

  const isReadyToPost =
    !isPosting && !isLoadingMedia && (hasAssets || postHasContent)

  const { t } = useTypedTranslation(['forms'])
  const newPostFormHook = useZodFormHook({
    schema: newPostSchema,
    defaultValues: {
      content: postId ? content : '',
    },
  })

  const imageUploadHook = useImageUpload()
  const videoUploadHook = useVideoUpload()
  const documentUploadHook = useDocumentUpload()

  useHandleFileUpload(imageUploadHook, assets, replaceAsset, addAsset)
  useHandleFileUpload(videoUploadHook, assets, replaceAsset, addAsset)
  useHandleFileUpload(documentUploadHook, assets, replaceAsset, addAsset)

  const isHookLoadingMedia =
    imageUploadHook.shouldDisplayPreview ||
    videoUploadHook.shouldDisplayPreview ||
    documentUploadHook.shouldDisplayPreview ||
    assets.some(
      (a) => !(a.url.includes('https://') || a.url.includes('http://')),
    )

  useEffect(() => {
    if (isHookLoadingMedia !== isLoadingMedia) {
      // setAppLoading(isHookLoadingMedia)
      setIsLoadingMedia(isHookLoadingMedia)
    }
  }, [isHookLoadingMedia])

  const handlePost = async (content: string) => {
    setIsPosting(true)
    if (!isReadyToPost) {
      return
    }

    toastSonner.promise(
      (async () => {
        if (postId) {
          await updatePost.mutateAsync({
            postId,
            content,
            assets,
            isAdvertisingPost,
          })
        } else {
          await createPost.mutateAsync({
            content,
            assets,
            socialAccounts: Array.from(selectedSocialAccounts),
            isAdvertisingPost,
          })
        }
        await utils.backend.posts.homeFeed.invalidate()

        if (isAdvertisingPost)
          await utils.backend.posts.advertisingAccountFeed.invalidate()
      })(),
      {
        position: isMobile ? 'top-center' : 'top-right',
        loading: `${postId ? 'Updating' : 'Creating'} your post...`,
        success: () => {
          // reset()
          return `Your post has been ${postId ? 'updated' : 'created'}`
        },

        error: (error) => {
          console.error('error??', error)
          // logger.error(error)
          return `There was an error ${postId ? 'updating' : 'creating'} your post`
        },
      },
    )
  }

  return (
    <Credenza
      open={isDialogOpen}
      onOpenChange={(opened) => {
        setIsDialogOpen(opened)
        if (!opened) {
          reset()
        }
      }}
    >
      <CredenzaContent
        aria-describedby={postId ? 'Update Post' : 'New Post'}
        onInteractOutside={(e) => {
          e.preventDefault()
        }}
        onEscapeKeyDown={(e) => e.preventDefault()}
        className="flex max-h-[calc(100dvh)] min-h-[calc(100dvh)] flex-col justify-start md:max-h-[calc(85dvh)] md:min-h-[calc(85dvh)]"
      >
        <CredenzaHeader className="flex-initial pb-2">
          {!isMobile ? (
            <CredenzaTitle className="">
              {postId ? 'Update Post ' : 'New Post'}
            </CredenzaTitle>
          ) : (
            <CredenzaTitle className="">
              <div className="flex w-full justify-end pr-2">
                {isMobile && (
                  <Button
                    type="submit"
                    disabled={!isReadyToPost || isPosting}
                    form="create-post-form"
                  >
                    {isLoadingMedia ? '...loading' : postId ? 'Update' : 'Post'}
                  </Button>
                )}
              </div>
            </CredenzaTitle>
          )}
        </CredenzaHeader>

        <CredenzaBody className="flex-1">
          <Form
            formHook={newPostFormHook}
            onSuccess={async (data) => {
              setIsDialogOpen(false)
              await handlePost(data.content)
            }}
            id={'create-post-form'}
          >
            <div className="max-w-[555px]">
              <RichTextArea
                name={newPostFormHook.path.content}
                placeholder={t('placeholder-new-post', { ns: 'forms' })}
                formHook={newPostFormHook}
                onChange={(html) => {
                  setContent(html)
                }}
                autoFocus={true}
                defaultValue={content}
                onImagePressed={() => {
                  if (isHookLoadingMedia) {
                    return
                  }
                  setUploadType('image')
                }}
                onVideoPressed={() => {
                  if (isHookLoadingMedia) {
                    return
                  }
                  setUploadType('video')
                }}
                onDocumentPressed={() => {
                  if (isHookLoadingMedia) {
                    return
                  }
                  setUploadType('document')
                }}
              />
            </div>

            <div className="max-w-[555px]">
              {!!assets.length && (
                <AssetViewer
                  assets={assets}
                  isViewable={true}
                  postId={''}
                  isPreview={true}
                />
              )}
            </div>
          </Form>
        </CredenzaBody>

        {!isMobile && (
          <CredenzaFooter className="sticky -bottom-7 flex w-full flex-col gap-2 border-t bg-white px-4 py-2">
            {/* Row 1: QuickShareToggle (aligned left) */}
            <div className="flex w-full">
              {!isMobile && selectedAccount?.id && (
                <div className="w-full">
                  <QuickShareToggle selectedAccountId={selectedAccount.id} />
                </div>
              )}
            </div>

            {/* Row 2: Post Button (aligned right) */}
            <div className="flex w-full justify-end pr-2 pt-2">
              {!isMobile && (
                <Button
                  type="submit"
                  disabled={!isReadyToPost || isPosting}
                  form="create-post-form"
                >
                  {isLoadingMedia ? '...loading' : postId ? 'Update' : 'Post'}
                </Button>
              )}
            </div>
          </CredenzaFooter>
        )}
      </CredenzaContent>

      <Dialog open={!!uploadType} onOpenChange={() => setUploadType(null)}>
        <DialogContent className="md:min-w-[850px]">
          <DialogHeader>
            <DialogTitle>Upload {uploadType}</DialogTitle>
          </DialogHeader>

          <div className="w-full flex-1">
            {uploadType ? (
              uploadType === 'image' ? (
                <ImageUpload
                  cropResizable={true}
                  onUploadButtonPressed={async (data) => {
                    await imageUploadHook.uploadProcessedAsset?.(data)
                    setUploadType(null)
                  }}
                  cropAspect="vertical"
                  availableAspects={['vertical', 'horizontal', 'square']}
                  isLoading={isLoadingMedia}
                />
              ) : uploadType === 'video' ? (
                <VideoUpload
                  onUploadButtonPressed={async (data) => {
                    await videoUploadHook.uploadVideoProcessedAsset?.(data)
                    setUploadType(null)
                  }}
                  isLoading={isLoadingMedia}
                />
              ) : uploadType === 'document' ? (
                <DocumentUpload
                  onUploadButtonPressed={async (data) => {
                    try {
                      await documentUploadHook.uploadProcessedAsset?.(data)
                      setUploadType(null)
                    } catch (err) {
                      console.error(err)
                      setUploadType(null)
                    }
                  }}
                  isLoading={isLoadingMedia}
                />
              ) : null
            ) : null}
          </div>
        </DialogContent>
      </Dialog>
    </Credenza>
  )
}
