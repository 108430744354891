export interface SizeVector<T> {
  width: T
  height: T
}

export const fitContainer = (
  aspectRatio: number,
  container: SizeVector<number>,
): SizeVector<number> => {
  let width = container.width
  let height = container.width / aspectRatio

  if (height > container.height) {
    width = container.height * aspectRatio
    height = container.height
  }

  return { width, height }
}
