import type { MarketplaceAsset } from '@sodium/shared-schemas'
import type { PDFDocumentProxy } from 'pdfjs-dist'
import React, { useState } from 'react'
import { adjustDimensions } from '@src/utils/adjustDimensions'
import { FileText, Pointer } from 'lucide-react'
import { useWindowSize } from 'react-use'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
import Image from 'next/image'
import { useTypedTranslation } from '@goatlab/marketplace-i18n'
import { defaultBackgroundImg } from '@src/utils/constants'
import { PdfModal } from './PdfModal'

interface PdfPreviewProps {
  asset: MarketplaceAsset
  isPreview?: boolean
}

export const PdfPreview: React.FC<PdfPreviewProps> = ({ asset }) => {
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [numPages, setNumPages] = useState<number>(0)
  const { t } = useTypedTranslation()

  const uri = asset.url
  const thumbnailUrl = asset.thumbnailUrl
  const height = asset.height || 0
  const width = asset.width || 0
  const totalPages = asset.pages || 1

  const dimensions = adjustDimensions({
    width,
    height,
    deviceWidth: useWindowSize().width,
  })

  const handleDocumentLoadSuccess = ({ numPages }: PDFDocumentProxy) => {
    setNumPages(numPages)
  }

  const handlePreviewClick = () => {
    setIsFullScreen(true)
  }

  if (!uri) {
    return null
  }

  return (
    <div className="relative flex h-full items-center justify-center">
      <div className="absolute left-4 top-4 z-10 rounded-3xl bg-[rgba(0,0,0,0.6)] p-2">
        <FileText size={20} color="white" />
      </div>
      <div className="absolute right-4 top-4 z-10 rounded-3xl bg-[rgba(0,0,0,0.6)] px-2 py-1">
        <span className="text-center text-sm text-white">
          {`1 / ${totalPages}`}
        </span>
      </div>

      {
        <Image
          onClick={handlePreviewClick}
          src={thumbnailUrl || defaultBackgroundImg}
          height={dimensions.height}
          width={width}
          alt={asset.originalName}
          className="h-full w-full cursor-pointer"
        />
      }

      <div className="absolute bottom-4 right-4 z-10 cursor-pointer rounded-3xl bg-[rgba(0,0,0,0.6)] px-3 py-2">
        <div
          className="flex flex-col items-center"
          onClick={() => handlePreviewClick()}
        >
          <Pointer size={24} color="white" />
          <span className="text-center text-sm font-bold text-white">
            {t('text-tap-to-view', { ns: 'common' })}
          </span>
        </div>
      </div>

      <PdfModal
        isShowed={isFullScreen}
        onSwipeComplete={setIsFullScreen}
        handleDocumentLoadSuccess={handleDocumentLoadSuccess}
        numPages={numPages}
        asset={asset}
      />
    </div>
  )
}
