// components/img/ImgPreview.tsx
import type { MarketplaceAsset } from '@sodium/shared-schemas'
import type { FC } from 'react'
import type { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch'
import { useRef, useState } from 'react'
import {
  Credenza,
  CredenzaBody,
  CredenzaClose,
  CredenzaContent,
  CredenzaTrigger,
} from '@goatlab/react-ui'
import { maxHomeFeedWidth } from '@src/consts'
import { adjustDimensions } from '@src/utils/adjustDimensions'
import { AlignCenter, RotateCcw, X, ZoomIn, ZoomOut } from 'lucide-react'
import { useWindowSize } from 'react-use'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'

interface ImgPreviewProps {
  asset: MarketplaceAsset
  isStory?: boolean
}

export const ImgPreview: FC<ImgPreviewProps> = ({ asset, isStory }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { width: windowWidth } = useWindowSize()
  const transformRef = useRef<ReactZoomPanPinchRef | null>(null)

  const height = asset.height || 0
  const width = asset.width || 0

  const dimensions = adjustDimensions({
    width,
    height,
    deviceWidth: Math.min(maxHomeFeedWidth, windowWidth),
    isStory,
  })

  const handleZoomIn = () => {
    transformRef.current?.zoomIn(0.5)
  }

  const handleZoomOut = () => {
    transformRef.current?.zoomOut(0.5)
  }

  const handleReset = () => {
    transformRef.current?.resetTransform()
  }

  const handleCenter = () => {
    transformRef.current?.centerView()
  }

  return (
    <Credenza open={isOpen} onOpenChange={setIsOpen} dismissible={false}>
      {/* Vista previa */}
      <CredenzaTrigger asChild>
        <div
          className={`flex cursor-pointer items-center justify-center ${
            isStory ? 'bg-black' : 'bg-slate-100'
          }`}
          style={{
            width: dimensions.width,
            height: dimensions.height,
          }}
        >
          <picture>
            <img
              src={asset.url || asset.thumbnailUrl}
              alt={asset.originalName}
              className="h-full w-full object-contain"
              style={{
                width: dimensions.width,
                height: dimensions.height,
              }}
            />
          </picture>
        </div>
      </CredenzaTrigger>

      {/* Modal con zoom */}
      <CredenzaContent className="flex h-dvh w-full items-center justify-center bg-slate-100 md:min-h-[calc(100dvh-200px)] md:max-w-screen-xl 2xl:max-w-screen-3xl">
        <CredenzaBody className="relative flex h-full w-full flex-col items-center justify-center p-0">
          {/* Controles */}
          <div className="absolute right-4 top-4 z-10 flex space-x-2 rounded-md bg-black/60 p-2">
            <button
              onClick={handleZoomIn}
              className="rounded-full p-2 text-white transition-colors hover:bg-black/80"
              title="Zoom In"
            >
              <ZoomIn className="h-5 w-5" />
            </button>
            <button
              onClick={handleZoomOut}
              className="rounded-full p-2 text-white transition-colors hover:bg-black/80"
              title="Zoom Out"
            >
              <ZoomOut className="h-5 w-5" />
            </button>
            <button
              onClick={handleReset}
              className="rounded-full p-2 text-white transition-colors hover:bg-black/80"
              title="Reset"
            >
              <RotateCcw className="h-5 w-5" />
            </button>
            <button
              onClick={handleCenter}
              className="rounded-full p-2 text-white transition-colors hover:bg-black/80"
              title="Center"
            >
              <AlignCenter className="h-5 w-5" />
            </button>
          </div>

          {/* Imagen con zoom */}
          <TransformWrapper
            initialScale={1}
            minScale={0.5}
            maxScale={5}
            centerOnInit
            wheel={{ step: 0.2 }}
            pinch={{ step: 0.2 }}
            ref={transformRef}
          >
            <TransformComponent
              wrapperStyle={{
                width: '100%',
                height: '100%',
              }}
            >
              <img
                src={asset.url}
                alt={asset.originalName}
                className="object-contain"
              />
            </TransformComponent>
          </TransformWrapper>

          {/* Botón de cerrar */}
          <CredenzaClose asChild>
            <button
              className="absolute left-4 top-4 rounded-full bg-black/60 p-2 text-white transition-colors hover:bg-black/80"
              onClick={() => setIsOpen(false)}
            >
              <X className="h-6 w-6" />
            </button>
          </CredenzaClose>
        </CredenzaBody>
      </CredenzaContent>
    </Credenza>
  )
}
