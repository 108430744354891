import type { FC, ReactNode } from 'react'
import React, { useEffect, useState } from 'react'
import {
  Credenza,
  CredenzaBody,
  CredenzaClose,
  CredenzaContent,
  CredenzaFooter,
  CredenzaHeader,
  CredenzaTitle,
  DialogDescription,
} from '@goatlab/react-ui'
import { useIs } from '@src/hooks/useIs'
import { useWindowSize } from '@src/utils/use-window-size'
import clsx from 'clsx'
import { X } from 'lucide-react'

// More docs in https://vaul.emilkowal.ski/snap-points

interface DrawerDialogProps {
  open: boolean
  title: string | ReactNode
  onClose: () => void
  children: ReactNode
  footer?: ReactNode
  dismissible?: boolean
  snapPoints?: (number | string)[]
  snapToSequentialPoint?: boolean
  onSnapPointChange?: (snap: number | string) => void
  onVisibleScrollHeightChange?: (snap: number) => void
}

const defaultSnaps = [0.5, 1]

const offsets = {
  '0.1': 300,
  '0.2': 300,
  '0.3': 300,
  '0.4': 250,
  '0.5': 250,
  '0.6': 200,
  '0.7': 150,
  '0.8': 150,
  '0.9': 150,
  '1': 120,
}

const getOffset = (snp?: number | string) => {
  if (!snp) {
    return 200
  }

  const key = String(snp) as any

  const possibleOffset = (offsets as any)[key] as number

  if (possibleOffset) {
    return possibleOffset
  }

  return 300
}

export const DrawerDialog: FC<DrawerDialogProps> = ({
  open,
  onClose,
  children,
  title,
  dismissible = true,
  snapPoints,
  snapToSequentialPoint = true,
  onSnapPointChange,
  onVisibleScrollHeightChange,
  footer,
}) => {
  const { isMobile } = useIs()
  const { height } = useWindowSize()

  const snaps = snapPoints?.length ? snapPoints : defaultSnaps
  const [snap, setSnap] = useState<number | string>(snaps[0])

  useEffect(() => {
    onVisibleScrollHeightChange?.(Number(snap) * (height - getOffset(snap)))
  }, [])

  return (
    <Credenza
      open={open}
      onOpenChange={onClose}
      dismissible={dismissible}
      snapPoints={snapPoints}
      activeSnapPoint={snap}
      setActiveSnapPoint={(s) => {
        if (!s) {
          return
        }
        setSnap(s)
        onSnapPointChange?.(s)
        onVisibleScrollHeightChange?.(
          Number(Number(s) * (height - getOffset(s))),
        )
      }}
      repositionInputs={false}
      snapToSequentialPoint={snapToSequentialPoint}
    >
      <CredenzaContent
        className={clsx(
          'overflow-hidden p-0 shadow-lg',
          isMobile &&
            'border-b-none fixed bottom-0 left-0 right-0 mx-[-1px] flex h-full max-h-[97%] flex-col rounded-t-[10px] border border-gray-200 bg-white p-4',
        )}
      >
        {isMobile && (
          <CredenzaClose asChild>
            <div
              className="absolute right-2 top-0 z-10 cursor-pointer rounded-full text-black"
              style={{
                backgroundColor: 'white',
                padding: 4,
                margin: 15,
                borderRadius: 20,
              }}
              onClick={() => onClose()}
            >
              <X className="h-5 w-5" />
            </div>
          </CredenzaClose>
        )}
        <CredenzaHeader
          className={clsx('border-b-1 border-b-slate-100 pb-0', {
            'cursor-grab': isMobile,
          })}
        >
          <CredenzaTitle className="mx-auto" hideCloseButton={true}>
            <div className="text-[15px]">{title}</div>
          </CredenzaTitle>
          <DialogDescription>{null}</DialogDescription>
          {!isMobile && (
            <CredenzaClose asChild>
              <div
                className="absolute right-4 top-2 z-10 cursor-pointer rounded-full p-2 text-black"
                onClick={onClose}
              >
                <X className="h-5 w-5" />
              </div>
            </CredenzaClose>
          )}
        </CredenzaHeader>

        <div className={clsx('mx-auto flex w-full flex-col overflow-hidden')}>
          <CredenzaBody className="pb-4">{children}</CredenzaBody>
        </div>

        {footer && !isMobile ? (
          <CredenzaFooter className="w-full">{footer}</CredenzaFooter>
        ) : (
          footer
        )}
      </CredenzaContent>
    </Credenza>
  )
}
