import type { LinkProps as NextLinkProps } from 'next/link'
import type { FC, ReactNode } from 'react'
import { forwardRef } from 'react'
import NextLink from 'next/link'

type LinkProps = NextLinkProps & { className?: string; children: ReactNode }

const Link: FC<LinkProps> = forwardRef(({ href, children, ...props }, ref) => {
  return (
    <NextLink href={href} ref={ref as any} {...props}>
      {children}
    </NextLink>
  )
})

export default Link
