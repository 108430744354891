import type { FC } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { Drawer } from '@components/common/drawer/drawer'
import { MobileFooter } from '@components/layout/footer/mobileFooter'
import { ProfileNav } from '@components/spaces/profileSpaceNav'
import { Logo } from '@components/ui/Logo'
import { useUIStore } from '@sodium/shared-frontend-schemas'
import { useIs } from '@src/hooks/useIs'
import { getDirection } from '@utils/get-direction'
import { Facebook, Instagram, Twitter, X, Youtube } from 'lucide-react'

const HomeDrawerLeft = dynamic(() =>
  import('@components/layout/drawerLeft/HomeDrawerLeft').then(
    (i) => i.HomeDrawerLeft,
  ),
)

const AccountDrawerLeft = dynamic(() =>
  import('@components/layout/drawerLeft/AccountDrawerLeft').then(
    (i) => i.AccountDrawerLeft,
  ),
)

const social = [
  {
    id: 0,
    link: 'https://www.facebook.com/gealium',
    icon: <Facebook />,
    className: 'facebook',
    title: 'text-facebook',
  },
  {
    id: 1,
    link: 'https://twitter.com/gealium',
    icon: <Twitter />,
    className: 'twitter',
    title: 'text-twitter',
  },
  {
    id: 2,
    link: 'https://www.youtube.com/@gealium',
    icon: <Youtube />,
    className: 'youtube',
    title: 'text-youtube',
  },
  {
    id: 3,
    link: 'https://www.instagram.com/gealium_com',
    icon: <Instagram />,
    className: 'instagram',
    title: 'text-instagram',
  },
]

const BottomNavigation: FC = () => {
  const { closeSideBar, displaySidebar } = useUIStore()
  const { locale } = useRouter()
  const dir = getDirection(locale)
  const contentWrapperCSS = dir === 'ltr' ? { left: 0 } : { right: 0 }
  const { isAccountPage, isSpacePage } = useIs()

  return (
    <>
      <MobileFooter />

      <Drawer
        placement={dir === 'rtl' ? 'right' : 'left'}
        open={displaySidebar}
        onClose={closeSideBar}
        contentWrapperStyle={contentWrapperCSS}
        width={dir === 'rtl' ? 'calc(100% - 6rem)' : 'calc(100% - 6rem)'}
      >
        <div className="relative flex w-full flex-shrink-0 items-center justify-between border-b border-gray-100 py-0.5 ps-5 md:ps-7">
          <Logo keepText={true} />

          <button
            className="flex items-center justify-center px-4 py-6 text-2xl text-gray-500 transition-opacity hover:opacity-60 focus:outline-none md:px-6 lg:py-8"
            onClick={closeSideBar}
            aria-label="close"
          >
            <X className="mt-1 text-black md:mt-0.5" />
          </button>
        </div>

        <div className="flex flex-col justify-between">
          {isAccountPage ? (
            <div className="px-8">
              <AccountDrawerLeft />
            </div>
          ) : isSpacePage ? (
            <ProfileNav />
          ) : (
            <HomeDrawerLeft />
          )}
        </div>

        <div className="space-s-1 flex flex-shrink-0 items-center justify-center border-t border-gray-100 bg-white px-7">
          {social?.map((item, index) => (
            <a
              href={item.link}
              className={`p-5 text-heading opacity-60 transition duration-300 ease-in first:-ms-4 hover:opacity-100 ${item.className}`}
              target="_blank"
              key={index}
              rel="noreferrer"
            >
              <span className="sr-only">{item.title}</span>
              {item.icon}
            </a>
          ))}
        </div>
      </Drawer>
    </>
  )
}

export default BottomNavigation
