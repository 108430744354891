'use client'

import Link from 'next/link'
import { backendHook } from '@api/trpc'
import { Button } from '@components/ui/buttons/Button2'
import {
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Skeleton,
  Switch,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@goatlab/react-ui'
import { ChevronDown, Facebook, Instagram, Linkedin } from 'lucide-react'
import { match } from 'ts-pattern'
import { create } from 'zustand'

interface QuickShareToggleProps {
  selectedAccountId: string
}

interface QuickShareState {
  selectedSocialAccounts: Set<string>
  resetSelectedSocialAccounts: () => void
  removeSelection: (accountId: string) => void
  addSelection: (accountId: string) => void
  hasSelection: (accountId: string) => boolean
}
export const useQuickShareStore = create<QuickShareState>((set, get) => ({
  selectedSocialAccounts: new Set<string>(),
  resetSelectedSocialAccounts() {
    set({ selectedSocialAccounts: new Set<string>() })
  },
  removeSelection: (accountId: string) => {
    set((state) => {
      const newSelectedSocialAccounts = state.selectedSocialAccounts
      newSelectedSocialAccounts.delete(accountId)
      return {
        ...state,
        selectedSocialAccounts: newSelectedSocialAccounts,
      }
    })
  },
  addSelection: (accountId: string) => {
    set((state) => {
      const newSelectedSocialAccounts = state.selectedSocialAccounts
      newSelectedSocialAccounts.add(accountId)
      return {
        ...state,
        selectedSocialAccounts: newSelectedSocialAccounts,
      }
    })
  },
  hasSelection: (accountId: string) => {
    return get().selectedSocialAccounts.has(accountId)
  },
}))

type SupportedSocialNetwork = 'facebook' | 'instagram' | 'linkedin'

const getSocialNetworkConfig = (network: SupportedSocialNetwork) => {
  return match(network)
    .with('facebook', () => ({
      icon: Facebook,
      label: 'Facebook',
    }))
    .with('instagram', () => ({
      icon: Instagram,
      label: 'Instagram',
    }))
    .with('linkedin', () => ({
      icon: Linkedin,
      label: 'LinkedIn',
    }))
    .exhaustive()
}

const SocialNetwork = ({
  id,
  accounts,
  selectedAccountId,
}: {
  id: SupportedSocialNetwork
  selectedAccountId: string
  accounts: {
    id: string
    name?: string | undefined | null
  }[]
}) => {
  const { icon: Icon, label } = getSocialNetworkConfig(id)
  const { hasSelection, addSelection, removeSelection } = useQuickShareStore()

  if (accounts.length === 0) {
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <div className="flex items-center gap-2">
              <Icon className="h-4 w-4 text-muted-foreground" />
              <span className="sr-only">{label}</span>
              <Switch disabled className="opacity-50" />
            </div>
          </TooltipTrigger>
          <TooltipContent>
            <div className="text-sm">
              Connect your {label} account to share posts
              <Button variant="link" className="ml-1 h-auto p-0" asChild>
                <Link
                  href={`/account/${selectedAccountId}/settings/social`}
                  target="_blank"
                  className="underline"
                >
                  Connect now
                </Link>
              </Button>
            </div>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    )
  }

  if (accounts.length === 1) {
    const account = accounts[0]
    return (
      <div className="flex items-center gap-2">
        <Label htmlFor={id} className="flex items-center gap-1">
          <Icon className="h-4 w-4" />
          <span className="sr-only">{label}</span>
        </Label>
        <Switch
          id={id}
          checked={hasSelection(account.id)}
          onCheckedChange={(checked) =>
            checked ? addSelection(account.id) : removeSelection(account.id)
          }
        />
      </div>
    )
  }

  const selectedCount = accounts.reduce((acc, account) => {
    return hasSelection(account.id) ? acc + 1 : acc
  }, 0)

  const handleToggle = () => {
    accounts.forEach((account) => {
      if (hasSelection(account.id)) {
        removeSelection(account.id)
      }
    })
  }

  return (
    <Popover>
      <div className="flex items-center gap-2">
        <Label htmlFor={id} className="flex items-center gap-1">
          <Icon className="h-4 w-4" />
          <span className="sr-only">{label}</span>
        </Label>
        <Switch checked={selectedCount > 0} onCheckedChange={handleToggle} />
        <PopoverTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            className="h-6 gap-1 px-2 text-xs text-muted-foreground"
          >
            {selectedCount > 0 ? (
              <span>{selectedCount} selected</span>
            ) : (
              <span>Select accounts</span>
            )}
            <ChevronDown className="h-3 w-3" />
          </Button>
        </PopoverTrigger>
      </div>
      <PopoverContent className="w-[200px] p-2" align="start">
        <div className="space-y-2">
          {accounts.map((account) => (
            <label
              key={account.id}
              className="flex items-center space-x-3 rounded p-1 hover:bg-accent"
            >
              <Switch
                checked={hasSelection(account.id)}
                onCheckedChange={(checked) => {
                  if (checked) {
                    addSelection(account.id)
                  } else {
                    removeSelection(account.id)
                  }
                }}
              />
              <div className="flex flex-1 items-center gap-2">
                <span className="text-sm">{account.name}</span>
              </div>
            </label>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  )
}

export function QuickShareToggle({ selectedAccountId }: QuickShareToggleProps) {
  const accountQuery = backendHook.social.account.getAccounts.useQuery({
    accountId: selectedAccountId,
  })
  return (
    <div className="flex flex-col items-center gap-4 self-start">
      <div className="-mb-1 flex-1 self-start text-xs font-medium">
        Also share to:
      </div>
      <div className="flex items-center gap-4">
        {match(accountQuery)
          .with(
            {
              status: 'pending',
            },
            () => {
              return <Skeleton className="h-4 w-4" />
            },
          )
          .with(
            {
              status: 'success',
            },
            ({ data: { accounts } }) => {
              const instagramAccounts = accounts.filter(
                (acc) => acc.provider === 'instagram',
              )

              const linkedinAccounts = accounts.filter(
                (acc) => acc.provider === 'linkedin',
              )
              const facebookAccounts = accounts.filter(
                (acc) => acc.provider === 'facebook',
              )

              return (
                <>
                  <SocialNetwork
                    id="facebook"
                    accounts={facebookAccounts}
                    selectedAccountId={selectedAccountId}
                  />
                  <SocialNetwork
                    id="instagram"
                    accounts={instagramAccounts}
                    selectedAccountId={selectedAccountId}
                  />
                  <SocialNetwork
                    id="linkedin"
                    accounts={linkedinAccounts}
                    selectedAccountId={selectedAccountId}
                  />
                </>
              )
            },
          )
          .with(
            {
              status: 'error',
            },
            () => {
              return <p>Error</p>
            },
          )
          .exhaustive()}
      </div>
    </div>
  )
}
