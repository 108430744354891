import type { Player } from '@goatlab/react-ui'
import type { MarketplaceAsset } from '@sodium/shared-schemas'
import type { FC } from 'react'
import { useEffect, useRef, useState } from 'react'
import { videojs } from '@goatlab/react-ui'
import { maxHomeFeedWidth } from '@src/consts'
import useDelay from '@src/hooks/useDelay'
import { useIs } from '@src/hooks/useIs'
import { adjustDimensions } from '@src/utils/adjustDimensions'
import { useWindowSize } from '@src/utils/use-window-size'
import { fitContainer } from '../fitContainer'

interface VideoPlayerProps {
  asset: MarketplaceAsset
  index: number
  currentAssetIndex: number
  isAssetViewable?: boolean
  isStory?: boolean
}

export const VideoPlayer: FC<VideoPlayerProps> = ({
  asset,
  isStory,
  index,
  currentAssetIndex,
  isAssetViewable,
}) => {
  const videoRef = useRef<HTMLDivElement | null>(null)
  const playerRef = useRef<Player | null>(null)
  const [, setIsFullScreen] = useState(false)
  const isPlayingRef = useRef<boolean>(false) // Store latest `isPlaying` state
  const [isPlaying, setIsPlaying] = useState(false)
  const shouldPlay = currentAssetIndex === index && isAssetViewable
  const debounceShouldPlay = useDelay(shouldPlay, 1000)
  const { isMobile } = useIs()

  const { isVertical } = asset
  const isHorizontal = (asset?.width || 0) > (asset?.height || 0)
  const thumbnailUrl = asset.thumbnailUrl
  // const { isMuted, setIsMuted } = useFlashListStore()

  const { width: totalWidth } = useWindowSize()

  const width = Math.min(maxHomeFeedWidth, totalWidth)

  const calculatedHeight = isVertical
    ? isStory
      ? (width * 9) / 16
      : (width * 3) / 2
    : isHorizontal
      ? (width * 16) / 9
      : width

  const size = fitContainer((asset?.width || 0) / (asset?.height || 1), {
    width,
    height: calculatedHeight,
  })

  const dimensions = adjustDimensions({
    width: asset.width,
    height: asset.height,
    deviceWidth: width,
    isStory,
  })

  useEffect(() => {
    if (videoRef.current && !playerRef.current) {
      const videoElement = document.createElement('video-js')
      videoElement.classList.add('vjs-big-play-centered', 'vjs-theme-fantasy')
      videoRef.current.appendChild(videoElement)

      playerRef.current = videojs(videoElement, {
        controls: true,
        autoplay: true,
        muted: true,
        fluid: true,
        fill: true,
        preload: 'metadata', // Cargar solo metadatos inicialmente
        loop: true,
        poster: thumbnailUrl, // Usar thumbnail hasta iniciar la reproducción
        bigPlayButton: false,
        sources: [{ src: asset.url, type: 'video/mp4' }],
        html5: {
          hls: {
            withCredentials: true,
            overrideNative: true,
          },
        },
      })

      playerRef.current.on('fullscreenchange', () => {
        setIsFullScreen(playerRef.current?.isFullscreen() || false)
      })

      playerRef.current.on(['waiting', 'pause'], function () {
        setIsPlaying(false)
      })

      playerRef.current.on('playing', function () {
        setIsPlaying(true)
      })

      // Click Event - Uses `isPlayingRef` for Latest State
      // using the useState wont work
      playerRef.current.on('click', (event: any) => {
        if (event.target.tagName === 'VIDEO') {
          if (!isPlayingRef.current && shouldPlay) {
            void playerRef.current?.play()
          }

          // Only go full screen on mobile devices
          // desktop can always click the full screen button
          if (isPlayingRef.current && isMobile) {
            void playerRef.current?.requestFullscreen()
          }
        }
      })
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose()
        playerRef.current = null
      }
    }
  }, [asset])

  useEffect(() => {
    isPlayingRef.current = isPlaying
  }, [isPlaying])

  useEffect(() => {
    if (playerRef.current && debounceShouldPlay) {
      if (playerRef?.current?.play) {
        void playerRef.current.play()
      }
    }

    if (playerRef.current && !debounceShouldPlay) {
      playerRef.current.pause()
      playerRef.current.currentTime(0)
    }
  }, [debounceShouldPlay])

  return (
    <div
      className={`flex items-center justify-center ${isStory ? 'bg-black' : 'bg-white'}`}
      style={{
        width: dimensions.width,
        height: dimensions.height,
      }}
    >
      <div
        ref={videoRef}
        style={{
          alignSelf: 'center',
          width: size.width - 40,
          height: size.height - 40,
        }}
      />
    </div>
  )
}
