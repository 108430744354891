import type { FC } from 'react'
import React from 'react'
import Image from 'next/image'
import { sodiumConfig } from '@src/sodiumConfig'
import { defaultAccountImage } from '@src/utils/constants'

interface AvatarProps {
  uri?: string | null
  size: number
}

export const Avatar: FC<AvatarProps> = ({ uri, size }) => {
  return (
    <Image
      src={uri || defaultAccountImage}
      alt="picture"
      width={size}
      height={size}
      style={{
        width: size,
        height: size,
        // borderRadius: size,
        backgroundColor: sodiumConfig.colors.iconColor,
        borderWidth: 0.5,
        borderColor: sodiumConfig.colors.iconColor,
      }}
      className={`aspect-square h-[${size}] w-[${size}] min-h-[${size}] min-w-[${size}] rounded-full border border-gray-300 bg-white`}
    />
  )
}
