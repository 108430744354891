import type QuillClass from 'quill'
import type { FC } from 'react'
import React, { useEffect, useRef } from 'react'
import { useParseInputProps } from '@goatlab/react-zod-form'
import { CameraIcon, FileText, Video } from 'lucide-react'
import { Controller } from 'react-hook-form'
import type { BaseField } from '../types/baseField'
import { Label } from '../Label'
import { QuillEditor } from './QuillEditor'

type RichTextAreaProps = BaseField<string> & {
  minHeight?: string
  autoFocus?: boolean
  onImagePressed?: () => void
  onVideoPressed?: () => void
  onDocumentPressed?: () => void
  config?: {
    image?: boolean
    video?: boolean
    document?: boolean
  }
}

export const RichTextArea: FC<RichTextAreaProps> = ({
  className = '',
  label,
  name: nameProp,
  formHook: hook,
  defaultValue,
  onChange,
  description,
  placeholder,
  disabled,
  autoFocus,
  onImagePressed,
  onVideoPressed,
  onDocumentPressed,
  config,
}) => {
  const quillRef = useRef<QuillClass | null>(null)

  const imageEnabled = config?.image ?? true
  const videoEnabled = config?.video ?? true
  const documentEnabled = config?.document ?? true

  const { name, formHook, errorMessage } = useParseInputProps({
    name: nameProp,
    formHook: hook,
  })

  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        quillRef.current?.focus()
      }, 50)
    }
  }, [quillRef.current])

  useEffect(() => {
    if (defaultValue) {
      quillRef.current?.clipboard.dangerouslyPasteHTML(defaultValue)
    }
  }, [quillRef.current])

  const value = formHook.getValues(name)

  return (
    <div className={className}>
      {label && <Label text={label} />}
      <Controller
        control={formHook?.control}
        name={name}
        defaultValue={defaultValue || value}
        disabled={disabled}
        render={({ field: { disabled, onChange: hookChange } }) => {
          return (
            <>
              <div id="custom-toolbar">
                <button className="ql-bold"></button>
                <button className="ql-italic"></button>
                <button className="ql-underline"></button>
                {imageEnabled && (
                  <button className="ql-image">
                    <CameraIcon size={16} />
                  </button>
                )}
                {videoEnabled && (
                  <button className="ql-video">
                    <Video size={16} />
                  </button>
                )}
                {documentEnabled && (
                  <button className="ql-document">
                    <FileText size={16} />
                  </button>
                )}
              </div>
              <QuillEditor
                quillRef={quillRef}
                readOnly={disabled}
                defaultValue={defaultValue || value}
                onTextChange={() => {
                  const content = quillRef.current?.root.innerHTML

                  if (typeof content !== 'string') {
                    return
                  }

                  hookChange(content)
                  onChange?.(content)
                }}
                options={{
                  placeholder: placeholder || 'start typing...',
                  modules: {
                    toolbar: {
                      container: '#custom-toolbar',
                      handlers: {
                        document: () => {
                          onDocumentPressed?.()
                        },
                        image: () => {
                          onImagePressed?.()
                        },
                        video: () => {
                          onVideoPressed?.()
                        },
                      },
                    },
                  },
                  readOnly: disabled,
                }}
              />
            </>
          )
        }}
      />
      <h4 className="mb-1 ml-2 mt-1 block text-xs font-medium">
        {description}
      </h4>
      {errorMessage && (
        <div className="mt-2 pl-1 text-xs text-red-600">
          {String(errorMessage)}
        </div>
      )}
    </div>
  )
}
