import type { FC, ReactNode } from 'react'
import { useAuth } from '@components/auth/store/auth.store'
import MobileNavigation from '@components/layout/drawerLeft/mobileDrawer'
import { Footer } from '@components/layout/footer/footer'
import { MainTopHeader } from '@components/layout/header/MainTopHeader'
import { Search } from '@components/search/globalSearch/Search'
import { NoSSR } from '@components/ui/utils/NoSSR'
import { useSite } from '@contexts/site/site.context'
import { SidebarProvider } from '@goatlab/react-ui'
import { env } from '@src/_env/env'
import { useIs } from '@src/hooks/useIs'
import { sodiumConfig } from '@src/sodiumConfig'
import { useTypedTranslation } from '@src/utils/useTypedTranslation'
// import { useAcceptCookies } from '@utils/use-accept-cookies'
// import { CookieBar } from '@components/common/CookieBar'
import { NextSeo } from 'next-seo'

interface LayoutProps {
  children: ReactNode
  showFooter?: boolean
}
export const AppLayout: FC<LayoutProps> = ({ children, showFooter = true }) => {
  // const { acceptedCookies, onAcceptCookies } = useAcceptCookies()
  const { isMobile, isMainLanding, isAccountPage, isTablet, isSpacePage } =
    useIs()
  const { isSpace, isExternalDomain, domain, slug } = useSite()
  const { user } = useAuth()
  const { t } = useTypedTranslation('common')

  const shouldDisplaySpaceSEO = !!(isSpace && isExternalDomain)
  const shouldDisplayBottomNav =
    (!isMainLanding || !sodiumConfig.hasLandingPage) && user?.account

  const shouldDisplayTopHeader =
    (isAccountPage && !sodiumConfig.hasLandingPage) ||
    sodiumConfig.hasLandingPage ||
    isSpacePage

  const baseDomain = `https://${env.BASE_DOMAIN}/`

  const metaTitle = `${sodiumConfig.seo.name} - ${sodiumConfig.seo.description}`

  return (
    <SidebarProvider
      style={{
        '--sidebar-width': isTablet ? '12rem' : '16rem',
        '--sidebar-width-mobile': '18rem',
      }}
    >
      <NextSeo
        additionalMetaTags={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1.0',
          },
        ]}
        title={shouldDisplaySpaceSEO ? slug : metaTitle}
        description={domain ?? ''}
        canonical={baseDomain}
        openGraph={{
          url: shouldDisplaySpaceSEO ? domain : baseDomain,
          title: metaTitle ?? '',
          description: t('meta-description') ?? '',
          images: [
            {
              url: sodiumConfig.topHeader.logo,
              width: 800,
              height: 600,
              alt: `${env.APP_NAME} logo`,
            },
            {
              url: sodiumConfig.topHeader.logo,
              width: 900,
              height: 800,
              alt: `${env.APP_NAME} logo`,
            },
          ],
        }}
      />
      <main
        className="flex-1 overflow-x-hidden bg-slate-100"
        style={{
          WebkitOverflowScrolling: 'touch',
          minHeight: '100dvh',
        }}
      >
        <div className="mx-auto">
          <NoSSR>{shouldDisplayTopHeader && <MainTopHeader />}</NoSSR>
          {children}

          {showFooter && <Footer />}

          {isMobile && shouldDisplayBottomNav && (
            <NoSSR>
              <MobileNavigation />
            </NoSSR>
          )}

          <Search />

          {/* <CookieBar
            title={t('text-cookies-title')}
            hide={acceptedCookies}
            action={
              <Button
                onClick={() => {
                  onAcceptCookies()
                }}
                variant="slim"
              >
                {t('text-accept-cookies')}
              </Button>
            }
          /> */}
        </div>
      </main>
    </SidebarProvider>
  )
}
