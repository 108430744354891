import type { VariantProps } from 'class-variance-authority'
import type { ButtonHTMLAttributes, FC } from 'react'
import React from 'react'
import { Button as ShadcnButton } from '@goatlab/react-ui'
import { cn } from '@src/utils/cn'
import { cva } from 'class-variance-authority'

const buttonVariants = cva(
  `flex min-w-0 flex-row items-center justify-center gap-2 rounded-full font-semibold transition-colors`,
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-primary/90',
        secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        ghost:
          'bg-transparent text-secondary-foreground/70 hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
        outline:
          'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
      },
      size: {
        default: 'h-8 px-4 text-sm',
        sm: 'h-6 px-2 text-xs',
        lg: 'text-md h-12 px-8',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  className?: string
  loading?: boolean
  asChild?: boolean
}

export const Button: FC<ButtonProps> = ({
  className,
  variant = 'default',
  size = 'default',
  loading = false,
  disabled = false,
  children,
  type = 'button',
  ...props
}) => {
  const rootClassName = cn(
    buttonVariants({ variant, size }),
    {
      'pointer-events-none opacity-50': disabled || loading,
    },
    className,
  )

  return (
    <ShadcnButton
      className={cn(rootClassName)}
      disabled={disabled || loading}
      type={type}
      {...props}
    >
      {loading ? (
        <svg
          className="h-5 w-5 animate-spin"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      ) : (
        children
      )}
    </ShadcnButton>
  )
}
