import type { VideoUploadProps } from '@components/ui/video/VideoUpload'
import type { MarketplaceUploadHookResponse } from '@src/types/assets'
import { useCallback, useEffect, useRef } from 'react'
import { backendHook } from '@api/trpc'
import { Promises } from '@goatlab/js-utils'
import { toastSonner } from '@goatlab/react-ui'
import { gcpService } from '@src/services/gcp/gcp.service'
import {
  defaultMarketplaceAssetUploadState,
  useUploadState,
} from '@src/types/assets'
import { imageService } from '@src/utils/image/image.service'
import { videoService } from '@src/utils/video/video.service'
import { extractAssetMetadata } from './useImageUploads'

// TODO: Falta volver a mirara todo pero como metadata, el obtener el width y height del video y que la url tenga la terminación.mp4

export const useVideoUpload = (): MarketplaceUploadHookResponse => {
  const [assetUploadState, setAssetUploadState] = useUploadState()
  const videoBlobRef = useRef<Blob | null>(null)
  const thumbnailBlobRef = useRef<Blob | null>(null)

  const getUploadLinkHook =
    backendHook.backend.posts.getUploadSession.useMutation()

  const reset = useCallback(() => {
    setAssetUploadState(defaultMarketplaceAssetUploadState)
  }, [setAssetUploadState])

  const uploadVideoProcessedAsset = useCallback(
    async (videoUploadBlob: VideoUploadProps) => {
      reset()
      setAssetUploadState((state) => ({
        ...state,
        assetSelectionTriggered: true,
      }))
      try {
        videoBlobRef.current = videoUploadBlob.videoBlob
        const assetMetadata = assetMetadataFromBlobVideo(videoUploadBlob)

        const originalName = assetMetadata.originalName

        const thumbnailName = originalName.replace(/\.\w+$/, '.jpg')

        setAssetUploadState((state) => ({
          ...state,
          localAssetUrl: assetMetadata.url,
          assetPreviewImage: assetMetadata.url,
          isProcessingAsset: true,
          assetProcessingProgress: 0,
          assetMetadata,
        }))

        const { videoThumbnailFile } = await Promises.props({
          videoThumbnailFile: videoService.getThumbnailFile({
            videoBlob: videoBlobRef.current,
            timeSeconds: videoUploadBlob.videoSettings.customStartTime,
          }),
        })

        if (videoThumbnailFile) {
          thumbnailBlobRef.current = videoThumbnailFile?.thumbnailBlob
        }

        const start = performance.now()

        const { convertedVideo } = await Promises.props({
          convertedVideo: videoService.convertInBackground(
            {
              videoUploadBlob: {
                ...videoUploadBlob,
                videoBlob: videoBlobRef.current,
                fileName: originalName,
              },
              width: videoThumbnailFile?.dimensions.width,
              height: videoThumbnailFile?.dimensions.height,
            },
            (status) => {
              setAssetUploadState((state) => {
                return {
                  ...state,
                  isProcessingAsset: true,
                  localAssetUrl: assetMetadata.url,
                  assetProcessingProgress: status.progress,
                }
              })

              if (status.progress <= 1) {
                setAssetUploadState((state) => {
                  return { ...state, isProcessingAsset: true }
                })
              }

              if (status.progress === 1) {
                setAssetUploadState((state) => {
                  return { ...state, isProcessingAsset: true }
                })
              }

              if (status.currentFramePreview) {
                setAssetUploadState((state) => {
                  return {
                    ...state,
                    assetPreviewImage: status.currentFramePreview,
                    isProcessingAsset: true,
                  }
                })
              }
            },
          ),
        })

        console.log(
          `Execution time: ${(performance.now() - start) / 1000} seconds`,
        )

        if (convertedVideo) {
          videoBlobRef.current = convertedVideo.outputVideoBlob
        }

        const { thumbnailCompressedImage, thumbnailLinks, videoLinks } =
          await Promises.props({
            thumbnailCompressedImage: videoThumbnailFile
              ? imageService.resizeAndCompressImage({
                  imageFile: videoThumbnailFile.file,
                  type: 'thumbnail',
                })
              : undefined,
            thumbnailLinks: getUploadLinkHook.mutateAsync({
              fileName: `${thumbnailName}`,
              visibility: 'public',
            }),
            videoLinks: getUploadLinkHook.mutateAsync({
              fileName: `${convertedVideo.originalFileName}.mp4`,
            }),
          })

        setAssetUploadState((state) => {
          return {
            ...state,
            isProcessingAsset: false,
            signedUploadUrl: videoLinks.signedUploadURL,
            finalAssetUrl: videoLinks.url,
            processedAssetUri: convertedVideo?.outputVideoUri,
            processedThumbnailAssetUri: thumbnailCompressedImage?.compressedUrl,
            signedUploadThumbnailUrl: thumbnailLinks.signedUploadURL,
            assetMetadata: {
              ...assetMetadata,
              sizeBytes: videoBlobRef.current?.size,
              thumbnailUrl: thumbnailLinks.url,
              width: videoThumbnailFile?.dimensions.width,
              height: videoThumbnailFile?.dimensions.height,
              isVertical:
                (videoThumbnailFile?.dimensions.height || 0) >
                (videoThumbnailFile?.dimensions.width || 0),
            },
          }
        })
      } catch (err) {
        console.error(err)
        toastSonner.error('Error uploading image')
        reset()
      }
    },
    [getUploadLinkHook, reset],
  )

  useEffect(() => {
    if (
      assetUploadState.isProcessingAsset ||
      assetUploadState.isUploadingAsset ||
      assetUploadState.success ||
      assetUploadState.assetProcessingProgress !== 1
    ) {
      return
    }

    const uploadAsset = async () => {
      setAssetUploadState((state) => ({ ...state, isUploadingAsset: true }))
      try {
        if (!videoBlobRef.current || !thumbnailBlobRef.current) {
          throw new Error('No image blob')
        }

        await Promise.all([
          gcpService.uploadFileToSignedUrl({
            signedUrl: assetUploadState.signedUploadUrl || '',
            blob: videoBlobRef.current,
            mime: assetUploadState.assetMetadata?.mimeType || '',
          }),
          gcpService.uploadFileToSignedUrl({
            signedUrl: assetUploadState.signedUploadThumbnailUrl || '',
            blob: thumbnailBlobRef.current,
            mime: 'image/jpeg',
          }),
        ])

        setAssetUploadState((state) => ({
          ...state,
          isProcessingAsset: false,
          isUploadingAsset: false,
          success: true,
          done: true,
        }))
      } catch {
        toastSonner.error('Could not upload video')
        setAssetUploadState((state) => ({
          ...state,
          isUploadingAsset: false,
          success: false,
          done: true,
        }))
      }
    }

    if (
      assetUploadState.assetProcessingProgress === 1 &&
      assetUploadState.processedAssetUri &&
      assetUploadState.signedUploadUrl &&
      assetUploadState.processedThumbnailAssetUri &&
      assetUploadState.signedUploadThumbnailUrl &&
      !assetUploadState.isUploadingAsset &&
      !assetUploadState.success
    ) {
      void uploadAsset()
    }
  }, [assetUploadState])

  const shouldDisplayPreview =
    assetUploadState.isProcessingAsset || assetUploadState.isUploadingAsset

  return {
    uploadVideoProcessedAsset,
    reset,
    shouldDisplayPreview,
    ...assetUploadState,
  }
}

export const assetMetadataFromBlobVideo = (inputValues: VideoUploadProps) => {
  const url = URL.createObjectURL(inputValues.videoBlob)
  const duration =
    inputValues.videoSettings.customEndTime -
    inputValues.videoSettings.customStartTime
  const sizeBytes = inputValues.videoBlob.size

  const asset = extractAssetMetadata({
    url,
    originalName: inputValues.fileName,
    mimeType: inputValues.mime,
    duration,
    sizeBytes,
  })

  return asset
}
