import type { FC } from 'react'
import { useEffect, useRef, useState } from 'react'
import { Cropper } from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import type { ImageUploadProps } from '@api/uploads'
import { Button } from '@components/ui/buttons/Button2'
import { SpinnerLoader } from '@components/ui/loaders/SpinnerLoader'
import clsx from 'clsx'
import {
  GalleryHorizontalEnd,
  RectangleHorizontal,
  RectangleVertical,
  Square,
} from 'lucide-react'
import { useDropzone } from 'react-dropzone'

export type CropAspect = 'horizontal' | 'vertical' | 'square' | 'story'

export interface PictureUploadProps {
  onUploadButtonPressed?: ({
    imageBlob,
    fileName,
    mime,
  }: ImageUploadProps) => void
  isLoading?: boolean
  cropResizable?: boolean
  cropAspect?: CropAspect
  availableAspects?: CropAspect[]
}

const getAspectRatio = (cropAspect?: CropAspect) => {
  let aspectRatio = 4 / 5

  switch (cropAspect) {
    case 'horizontal':
      aspectRatio = 16 / 9

      break
    case 'square':
      aspectRatio = 1

      break
    case 'story':
      aspectRatio = 9 / 16

      break
    case 'vertical':
      // could also be 2 / 3 but we would get edges
      aspectRatio = 4 / 5

      break

    default:
      aspectRatio = 4 / 5
      break
  }

  return aspectRatio
}
export const ImageUpload: FC<PictureUploadProps> = ({
  onUploadButtonPressed,
  isLoading,
  cropResizable,
  cropAspect,
  availableAspects = ['vertical', 'horizontal', 'square', 'story'],
}) => {
  const cropperRef = useRef<HTMLImageElement>(null)

  const [selectedAspect, setSelectedAspect] = useState<CropAspect>(
    cropAspect || 'vertical',
  )

  const [numericAspectRatio, setNumericAspectRatio] = useState<number>(
    getAspectRatio(selectedAspect),
  )

  const { acceptedFiles, getRootProps, getInputProps, isFileDialogActive } =
    useDropzone({
      accept: {
        'image/png': ['.png'],
        'image/jpeg': ['.jpeg', '.jpg'],
      },
      maxSize: 30000000,
      multiple: false,
    })

  const onUploadPressed = async () => {
    const cropper = (cropperRef?.current as any).cropper as Cropper
    cropper.getCroppedCanvas().toBlob((imageBlob) => {
      if (!imageBlob) {
        return
      }

      onUploadButtonPressed?.({
        imageBlob,
        fileName: acceptedFiles[0].name,
        mime: acceptedFiles[0].type,
      })
    })
  }

  useEffect(() => {
    setNumericAspectRatio(getAspectRatio(selectedAspect))
  }, [selectedAspect])

  return (
    <div className="relative flex w-full flex-1 flex-col overflow-y-auto overflow-x-hidden bg-white md:min-w-[600px]">
      <div className="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
        <div className="mb-2 grid grid-flow-col justify-start gap-2 pr-12 sm:auto-cols-max sm:justify-end">
          {!!acceptedFiles?.length &&
            availableAspects.map((aspect) => {
              return (
                <Button
                  key={aspect}
                  onClick={() => setSelectedAspect(aspect)}
                  size={'sm'}
                  className={clsx('text-xs text-white', {
                    'bg-black': selectedAspect !== aspect,
                    'bg-primary': selectedAspect === aspect,
                  })}
                >
                  {aspect === 'vertical' ? (
                    <RectangleVertical />
                  ) : aspect === 'square' ? (
                    <Square />
                  ) : aspect === 'horizontal' ? (
                    <RectangleHorizontal />
                  ) : aspect === 'story' ? (
                    <GalleryHorizontalEnd />
                  ) : null}
                  {aspect}
                </Button>
              )
            })}
        </div>

        <div className="m-auto max-w-2xl">
          <div className="px-4 text-center">
            {acceptedFiles?.length ? (
              isLoading ? (
                <>Uploading Picture...</>
              ) : (
                <Cropper
                  key={numericAspectRatio}
                  ref={cropperRef}
                  src={URL.createObjectURL(acceptedFiles[0])}
                  style={{ height: 400, width: '100%' }}
                  aspectRatio={numericAspectRatio}
                  autoCrop={true}
                  modal={true}
                  center={true}
                  zoomable={true}
                  cropBoxResizable={cropResizable ?? false}
                  cropBoxMovable={true}
                  guides={true}
                  // Just leaving this for reference so we know it is possible
                  // data={{
                  //   height: 1350,
                  //   width: 1080,
                  // }}
                  dragMode="move"
                  // autoCropArea={0.65}
                  // viewMode={2}
                />
              )
            ) : (
              <div
                {...getRootProps({ className: 'dropzone' })}
                className="cursor-pointer rounded-lg bg-slate-100 p-6"
              >
                <div className="mb-4 inline-flex h-16 w-16 items-center justify-center rounded-full bg-gradient-to-t from-slate-200 to-slate-100">
                  {isFileDialogActive ? (
                    <SpinnerLoader text="" className="mr-4" />
                  ) : (
                    <svg className="h-6 w-5 fill-current" viewBox="0 0 20 24">
                      <path
                        className="text-slate-500"
                        d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                      />
                      <path
                        className="text-slate-300"
                        d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                      />
                      <path
                        className="text-slate-400"
                        d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                      />
                    </svg>
                  )}
                </div>
                <h2 className="mb-2 text-2xl font-bold text-slate-800">
                  Upload your image
                </h2>
                <div className="mb-6">
                  Click to select files or Drag drop some files here
                </div>
                <input {...getInputProps()} />
              </div>
            )}
          </div>
        </div>

        {/* Right: Actions */}
        {acceptedFiles?.length ? (
          <div className="-mb-6 mt-6 grid grid-flow-col justify-start sm:auto-cols-max sm:justify-end">
            <Button
              variant="slim"
              onClick={onUploadPressed}
              loading={isLoading}
              color="black"
            >
              {isLoading ? 'Loading...' : 'Upload'}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  )
}
