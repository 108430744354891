import type { TypesenseMultiSearchQuery } from '@sodium/shared-frontend-services'
import { useSearch } from '@contexts/search/search.context'
import { useSite } from '@contexts/site/site.context'

export const useGlobalSearchFilter = () => {
  const { isSpace, accountId, isExternalDomain } = useSite()
  const perPage = 10
  const { q, categories } = useSearch()
  const productSearchArray: string[] = []
  const staffSearchArray: string[] = []

  if (categories?.length) {
    productSearchArray.push(`categories:${categories.join(',')}`)
  }

  if (isSpace || isExternalDomain) {
    productSearchArray.push(`accountId:${accountId}`)
    // Exact search
    staffSearchArray.push(`organizations:=${accountId}`)
  }

  const arrayFilter: TypesenseMultiSearchQuery = [
    {
      q: q || '',
      collection: 'accounts',
      query_by: 'displayName, slug, title',
      per_page: perPage,
      infix: 'always,always,always',
    },
    {
      q: q || '',
      collection: 'products',
      filter_by: productSearchArray.join('&'),
      query_by: '*',
      per_page: perPage,
    },
  ]

  return arrayFilter
}
