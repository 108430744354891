import React from 'react'
import SearchIcon from '@components/icons/search-icon'
import { Strings } from '@goatlab/js-utils'
import { env } from '@src/_env/env'
import { useTypedTranslation } from '@src/utils/useTypedTranslation'
import cn from 'clsx'
import { X } from 'lucide-react'

interface SearchProps {
  className?: string
  onSubmit?: (e: React.SyntheticEvent) => void
  onClear?: (e: React.SyntheticEvent) => void
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void
  onFocus?: () => void
  showClear?: boolean
  name?: string
  value?: string
  placeholder?: string
}

export const SearchBox = React.forwardRef<HTMLInputElement, SearchProps>(
  (
    { className, onSubmit, onClear, onFocus, showClear = false, ...rest },
    ref,
  ) => {
    const { t } = useTypedTranslation('search')
    const appName = Strings.upperFirst(env.APP_NAME)
    const placeHolder = `${t('search-in')} ${appName}`

    return (
      <form
        className={cn(
          'relative w-full overflow-hidden rounded-full bg-[#F0F3F6]',
          className,
        )}
        noValidate
        role="search"
        onSubmit={onSubmit}
      >
        <label htmlFor="searchBox" className="flex items-center">
          {/* Search Icon */}
          <span className="absolute left-3 top-1/2 -translate-y-1/2 transform">
            <SearchIcon className="h-5 w-5 text-gray-500" />
          </span>

          {/* Search Input */}
          <input
            id="searchBox"
            className="h-9 w-full rounded-md bg-[#F0F3F6] pl-10 pr-12 text-sm text-black placeholder-gray-400 outline-none"
            placeholder={placeHolder}
            aria-label="Search"
            autoComplete="off"
            onFocus={onFocus}
            ref={ref}
            {...rest}
          />
        </label>

        {/* Clear Button */}
        {showClear && (
          <button
            type="button"
            className="absolute right-3 top-1/2 -translate-y-1/2 transform text-gray-400 hover:text-gray-600"
            onClick={onClear}
          >
            <X className="h-5 w-5" />
          </button>
        )}
      </form>
    )
  },
)
