import type { JSX } from 'react'
import { useEffect, useRef } from 'react'
import { useRouter } from 'next/navigation'
import { AccountSearchResult } from '@components/account/home/AccountSearchResult'
import { DrawerDialog } from '@components/ui/dialog/DrawerDialog'
// import { FriendshipRecommendations } from '@components/network/FriendshipRecommendations'
import { useSearch } from '@contexts/search/search.context'
import { Strings } from '@goatlab/js-utils'
import { useTypedTranslation } from '@goatlab/marketplace-i18n'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Skeleton,
} from '@goatlab/react-ui'
import { useUIStore } from '@sodium/shared-frontend-schemas'
import { env } from '@src/_env/env'
import { useIs } from '@src/hooks/useIs'
import { typesenseService } from '@src/services/typesense/typesense.service'
import { useGlobalSearchFilter } from '../useGlobalSearchFilter'

// import { ProductSearchResult } from './ProductSearchResult'

interface EmptyComponentProps {
  q: string
  isPending: boolean
}

const EmptyComponent = ({ q, isPending }: EmptyComponentProps) => {
  if (isPending) {
    return (
      <>
        <Skeleton className="h-4 w-full" />
        <Skeleton className="h-4 w-full" />
        <Skeleton className="h-4 w-full" />
      </>
    )
  }

  if (!q) {
    return null
    // return <FriendshipRecommendations />
  }

  return <p>No results found.</p>
}

export function Search(): JSX.Element {
  const { displaySearch, closeSearch, openSearch } = useUIStore()
  const router = useRouter()
  const searches = useGlobalSearchFilter()
  const multi = typesenseService.useMultiSearch(searches, {
    enabled: displaySearch,
    refetchOnWindowFocus: false,
  })
  const { isMobile } = useIs()
  const { setSearchQuery, q } = useSearch()
  const appName = Strings.upperFirst(env.APP_NAME)
  const { t } = useTypedTranslation(['search'])
  const placeHolder = `${t('search-in')} ${appName}...`
  const listInnerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        if (displaySearch) {
          closeSearch()
        } else {
          openSearch()
        }
      }
    }
    document.addEventListener('keydown', down)
    return () => document.removeEventListener('keydown', down)
  }, [])

  return (
    <DrawerDialog
      title="Site search"
      open={displaySearch}
      onClose={() => {
        if (displaySearch) {
          setSearchQuery('')
          closeSearch()
        } else {
          openSearch()
        }
      }}
    >
      <Command shouldFilter={false}>
        <CommandInput
          placeholder={placeHolder}
          value={q}
          onValueChange={setSearchQuery}
        />
        <CommandList
          style={{
            maxHeight: isMobile ? '90dvh' : '50dvh',
          }}
          ref={listInnerRef}
        >
          <CommandEmpty>
            <EmptyComponent q={q} isPending={multi.isPending} />
          </CommandEmpty>

          {!!q &&
            Array.isArray(multi?.data) &&
            multi.data.map((collectionResults) => {
              const collectionName =
                collectionResults?.request_params?.collection_name

              const hits = Array.isArray(collectionResults?.hits)
                ? collectionResults.hits
                : []

              if (!hits.length) return null

              return (
                <CommandGroup key={collectionName} heading={collectionName}>
                  {hits.map((item: any) => {
                    return (
                      <CommandItem
                        key={item.document.id}
                        value={item.document.id}
                        onSelect={async () => {
                          await router.push(`/${item.document.slug}`)
                          closeSearch()
                        }}
                      >
                        {collectionName === 'accounts' && (
                          <AccountSearchResult
                            account={item.document}
                            onPressedIn={() => {
                              closeSearch()
                            }}
                            showDirectMessage={true}
                          />
                        )}

                        {/* {collectionName === 'products' && (
                        <ProductSearchResult item={item.document} />
                      )} */}
                      </CommandItem>
                    )
                  })}
                </CommandGroup>
              )
            })}
        </CommandList>
      </Command>
    </DrawerDialog>
  )
}
