import type { FC } from 'react'
import { useState } from 'react'
import Image from 'next/image'

interface ImageFixedHeightProps {
  src: string
  alt: string
  height: number
  className?: string
}

export const ImageFixedHeight: FC<ImageFixedHeightProps> = ({
  alt,
  height,
  src,
  className,
}) => {
  const [width, setWidth] = useState<number | null>(null)

  return (
    <div
      className={`h-auto rounded-md object-cover`}
      style={{
        width: width ? `${width}px` : 'auto',
        height: `${height}px`,
      }}
    >
      <Image
        src={src}
        alt={alt}
        width={width || height} // Default width as height to prevent resizing issues
        height={height}
        className={className || ''}
        onLoad={({ target }) => {
          const img = target as HTMLImageElement
          if (!img.naturalWidth || !img.naturalHeight) return

          const aspectRatio = img.naturalWidth / img.naturalHeight
          setWidth(height * aspectRatio) // Set a fixed width once the image loads
        }}
      />
    </div>
  )
}
