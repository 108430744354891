import type { MarketplaceAsset } from '@sodium/shared-schemas'
import type { FC } from 'react'
import { memo } from 'react'
import { NoSSR } from '@components/ui/utils/NoSSR'
import { ImgPreview } from './image/ImgPreview'
import { PdfPreview } from './pdf/PdfPreview'
import { VideoPlayer } from './video/VideoPlayer'

interface AssetRendererProps {
  asset: MarketplaceAsset
  index: number
  currentAssetIndex: number
  isAssetViewable: boolean
  isPreview?: boolean
  isStory?: boolean
}

export const AssetRenderer: FC<AssetRendererProps> = memo(
  ({
    asset,
    index,
    currentAssetIndex,
    isAssetViewable,
    isPreview,
    isStory,
  }) => {
    if (asset.isImage) {
      if (!asset.thumbnailUrl || !asset.url) {
        return null
      }
      return (
        <NoSSR>
          <ImgPreview asset={asset} isStory={isStory} />
        </NoSSR>
      )
    }

    if (asset.isVideo) {
      return (
        <NoSSR>
          <VideoPlayer
            asset={asset}
            isStory={isStory}
            currentAssetIndex={currentAssetIndex}
            index={index}
            isAssetViewable={isAssetViewable}
          />
        </NoSSR>
      )
    }

    if (asset.isFile && asset.mimeType === 'application/pdf') {
      return (
        <NoSSR>
          <PdfPreview asset={asset} isPreview={isPreview} />
        </NoSSR>
      )
    }

    return null
  },
)
