import { useRouter } from 'next/navigation'
import { useChatActions } from '@components/ui/chat/store/ChatStore'
import { useStateAwareRoutes } from '@src/hooks/useStateAwareRoutes'
import { defaultAccountImage } from '../constants'

export const useGoToConversation = () => {
  const router = useRouter()
  const routes = useStateAwareRoutes()
  const { setCurrentChatId, setCurrentChatRecipient, setMessages } =
    useChatActions()

  return {
    navigateToConversation: ({
      conversationId,
      account,
    }: {
      conversationId: string
      account?: {
        id: string
        name?: string | null
        avatar?: string | null
      }
    }) => {
      setCurrentChatId(conversationId)
      setMessages([])

      if (account) {
        setCurrentChatRecipient({
          _id: account.id,
          name: account.name || '',
          avatar: account.avatar || defaultAccountImage,
        })
      } else {
        setCurrentChatRecipient(undefined)
      }

      void router.push(
        routes.dynamic.ACCOUNT_INBOX({
          accountId: conversationId,
        }),
      )
    },
  }
}
