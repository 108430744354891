import type { CarouselApi } from '@goatlab/react-ui'
import type { MarketplaceAsset } from '@sodium/shared-schemas'
import type { PDFDocumentProxy } from 'pdfjs-dist'
import type { FC } from 'react'
import React, { useCallback, useEffect, useState } from 'react'
import Link from 'next/link'
import { Button } from '@components/ui/buttons/Button2'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  Credenza,
  CredenzaBody,
  CredenzaClose,
  CredenzaContent,
} from '@goatlab/react-ui'
import { useIs } from '@src/hooks/useIs'
import clsx from 'clsx'
import { DownloadIcon, X } from 'lucide-react'
import { Document, Page, pdfjs } from 'react-pdf'
import { useWindowSize } from 'react-use'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { fitContainer } from '../fitContainer'

interface PdfModalProps {
  isShowed: boolean
  onSwipeComplete: React.Dispatch<React.SetStateAction<boolean>>
  handleDocumentLoadSuccess: ({ numPages }: PDFDocumentProxy) => void
  asset: MarketplaceAsset
  numPages: number
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

function encodeURL(url: string) {
  const urlParts = url.split('/')
  const encodedParts = urlParts.map((part, index) => {
    if (index < 3) {
      return part
    } else {
      return encodeURIComponent(part)
    }
  })
  return encodedParts.join('/')
}

export const PdfModal: FC<PdfModalProps> = ({
  asset,
  isShowed,
  numPages,
  onSwipeComplete,
  handleDocumentLoadSuccess,
}) => {
  const { isMobile } = useIs()
  const { width: windowWidth, height } = useWindowSize()
  const maxWidthDesktop = 740
  const width = Math.min(windowWidth, maxWidthDesktop)
  const calculatedHeight = height * 0.8
  const [isPanningDisabled, setIsPanningDisabled] = useState(true)

  const size = fitContainer((asset?.width || 0) / (asset?.height || 1), {
    width,
    height: calculatedHeight - 100,
  })

  const [selectedIndex, setSelectedIndex] = useState(0)
  const [mainApi, setMainApi] = useState<CarouselApi>()
  const [thumbsApi, setThumbsApi] = useState<CarouselApi>()

  const encodedUrl = encodeURL(asset.url)

  const onThumbClick = useCallback(
    (index: number) => {
      if (!mainApi || !thumbsApi) return
      mainApi.scrollTo(index)
    },
    [mainApi, thumbsApi],
  )

  const onSelect = useCallback(() => {
    if (!mainApi || !thumbsApi) {
      return
    }
    const newIndex = mainApi.selectedScrollSnap()
    setSelectedIndex(newIndex)
    thumbsApi.scrollTo(newIndex - 2)
  }, [mainApi, thumbsApi])

  useEffect(() => {
    if (!mainApi || !thumbsApi) {
      return
    }

    onSelect()
    mainApi.on('select', onSelect).on('reInit', onSelect)
    thumbsApi.on('reInit', onSelect)

    return () => {
      mainApi.off('select', onSelect).off('reInit', onSelect)
      thumbsApi.off('reInit', onSelect)
    }
  }, [mainApi, thumbsApi, onSelect])

  useEffect(() => {
    if (mainApi && thumbsApi) {
      mainApi.scrollTo(0)
      thumbsApi.scrollTo(0)
    }
  }, [mainApi, thumbsApi])

  return (
    <Credenza
      open={isShowed}
      onOpenChange={onSwipeComplete}
      dismissible={selectedIndex === 0}
    >
      <CredenzaContent
        className={clsx(
          `flex w-full max-w-screen-lg flex-col bg-gray-200 p-4 md:bg-white`,
          {
            'h-[80dvh]': !isMobile,
            'h-[100dvh]': isMobile,
          },
        )}
      >
        <CredenzaClose asChild>
          <div
            className="absolute left-2 top-2 z-10 rounded-full p-3 text-white"
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              padding: 4,
              margin: 15,
              borderRadius: 20,
            }}
            onClick={() => onSwipeComplete(false)}
          >
            <X className="h-5 w-5" />
          </div>
        </CredenzaClose>

        <CredenzaBody
          className={clsx('flex flex-1 items-center justify-center gap-4')}
        >
          <Document
            file={asset.url}
            onLoadSuccess={handleDocumentLoadSuccess}
            options={{
              withCredentials: true,
            }}
            className="flex items-center gap-4"
          >
            <div className={clsx('flex items-center gap-12')}>
              {/* Miniature */}
              {!isMobile && (
                <Carousel
                  orientation="vertical"
                  setApi={setThumbsApi}
                  opts={{ containScroll: 'keepSnaps', dragFree: true }}
                  className={clsx(
                    `hidden h-[60dvh] overflow-hidden md:flex`,
                    {},
                  )}
                >
                  <CarouselContent className="h-[70px] gap-2 pt-6">
                    {Array.from({ length: numPages }, (_, index) => (
                      <CarouselItem
                        key={index}
                        className={clsx(
                          'cursor-pointer p-1 transition-all duration-200',
                          {
                            'border-2 border-primary bg-primary/10':
                              selectedIndex === index,
                            'hover:bg-gray-200': selectedIndex !== index,
                          },
                        )}
                        onClick={() => onThumbClick(index)}
                      >
                        <Page
                          pageNumber={index + 1}
                          width={70}
                          renderTextLayer={false}
                          renderAnnotationLayer={false}
                        />
                      </CarouselItem>
                    ))}
                  </CarouselContent>
                </Carousel>
              )}

              {/* Page Indicator */}
              <div className="absolute right-4 top-6 z-10 rounded-md bg-black/70 px-2 py-1 text-sm text-white">
                {selectedIndex + 1} / {numPages}
              </div>
              {/* Page Indicator */}

              {/* Main Carousel */}

              <Carousel
                orientation="vertical"
                setApi={setMainApi}
                opts={{
                  axis: 'y',
                  containScroll: 'keepSnaps',
                  align: 'center',
                  dragFree: false,
                }}
                className={clsx(
                  `justify-center overflow-hidden bg-gray-200/95`,
                  {
                    'h-[75dvh]': !isMobile,
                    'h-[100dvh]': isMobile,
                  },
                )}
              >
                {!isMobile && <CarouselPrevious />}

                <CarouselContent
                  className={clsx(`flex-col gap-4`, {
                    'h-[75dvh]': !isMobile,
                    'h-[100dvh]': isMobile,
                  })}
                >
                  {Array.from({ length: numPages }, (_, index) => (
                    <CarouselItem
                      key={index}
                      style={{
                        height: isMobile ? height : undefined,
                        alignContent: 'center',
                      }}
                    >
                      <div>
                        <TransformWrapper
                          centerZoomedOut={true}
                          centerOnInit={true}
                          onZoom={(ref) => {
                            const { scale } = ref.state
                            setIsPanningDisabled(scale === 1)
                          }}
                          panning={{ disabled: isPanningDisabled }}
                        >
                          <TransformComponent>
                            <Page
                              pageNumber={index + 1}
                              width={size.width}
                              renderTextLayer={false}
                              renderAnnotationLayer={false}
                            />
                          </TransformComponent>
                        </TransformWrapper>
                      </div>
                    </CarouselItem>
                  ))}
                </CarouselContent>

                {!isMobile && <CarouselNext />}
              </Carousel>

              {/* Download Icon */}
              <div className="absolute bottom-4 right-4 z-10 rounded-md bg-transparent px-2 py-1 text-sm text-white">
                <Button
                  variant="default"
                  size="sm"
                  style={{
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    color: 'white',
                  }}
                >
                  <Link
                    href={encodedUrl}
                    className="flex items-center gap-2"
                    download
                  >
                    <DownloadIcon className="h-4 w-4" />
                  </Link>
                </Button>
              </div>

              {/* Main Carousel */}
            </div>
          </Document>
        </CredenzaBody>
      </CredenzaContent>
    </Credenza>
  )
}
